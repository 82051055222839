import { configureStore, ThunkAction, combineReducers } from '@reduxjs/toolkit'
import { persistStore, persistReducer, createTransform } from 'redux-persist'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import storage from 'redux-persist/lib/storage'
import { createReduxHistoryContext } from 'redux-first-history'
import { Action } from 'redux'
import userSlice from './user'
import diagnosticSlice from './diagnostic'
import historyDataSlice from './history'
import applicationSlice from './application'
import { createMemoryHistory } from 'history'
import writeActionsMiddleware from '../middlewares/writeActionsMiddleware'
import { saveCurrentStateToDbDebounced } from '../hooks/useHistoryData'

const {
  createReduxHistory,
  routerMiddleware,
  routerReducer
} = createReduxHistoryContext({ history: createMemoryHistory(), reduxTravelling: true })

export const reducersToSave = ['diagnostic', 'router', 'user']
export const reducersToSaveToDb = ['diagnostic', 'router']


const rootReducer = combineReducers({
  router: routerReducer,
  user: userSlice,
  diagnostic: diagnosticSlice,
  historyData: historyDataSlice,
  application: applicationSlice
})

const SetTransform = createTransform(
  (inboundState, key, state) => {
    console.log('STORE inboundState', state.diagnostic)
    state.diagnostic.startTime && saveCurrentStateToDbDebounced(state)
    return { ...inboundState }
  },
  (outboundState, key) => {
    console.log('STORE outboundState', outboundState)
    return ({ ...outboundState });
  },
  { whitelist: reducersToSave }
)

const persistedReducer = persistReducer({
  key: 'currentSession',
  whitelist: reducersToSave,
  stateReconciler: autoMergeLevel2,
  transforms: [SetTransform],
  storage,
}, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    })
      .concat(routerMiddleware)
      .concat(writeActionsMiddleware),
})

export const history = createReduxHistory(store)
export const persistor = persistStore(store)

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
