import { useState, useCallback, useEffect } from 'react'
import * as serviceWorkerRegistration from '../serviceWorkerRegistration'
import { version } from '../utils/constants'

export const useServiceWorker = () => {
  const [waitingWorker, setWaitingWorker] = useState<ServiceWorker | null>(null)
  const [showReload, setShowReload] = useState(false)

  const onSWUpdate = useCallback((registration: ServiceWorkerRegistration) => {
    console.log('onSWUpdate')
    setShowReload(true)
    setWaitingWorker(registration.waiting)
  }, [])

  const reloadPage = useCallback(() => {
    waitingWorker?.postMessage({ type: "SKIP_WAITING" })
    setShowReload(false)
    window.location.reload()
  }, [waitingWorker])

  const skipReload = useCallback(() => {
    waitingWorker?.postMessage({ type: "SKIP_WAITING" })
    setShowReload(false)
  }, [waitingWorker])

  useEffect(() => {
    serviceWorkerRegistration.register(version, { onUpdate: onSWUpdate })
  }, [onSWUpdate])
  return { showReload, skipReload, waitingWorker, reloadPage }
}