import * as R from 'ramda'
import React, { useState } from 'react'
import { useDispatch, useSelector} from 'react-redux'
import html2PDF from 'jspdf-html2canvas'
import { Col, Divider, Grid, Panel, Row, Input, FlexboxGrid, toaster, Message, Loader } from 'rsuite'
import SelectorDrawer from '../components/selectordrawer'
import SelectorList, { SelectData } from '../components/selectorlist'
import { ActionDiv } from './selectDTC'
import Selector from '../components/selector'
import Card from '../components/card'
import styled from 'styled-components'
import Button from '../components/button'
import { toOptions } from '../utils'

import useProductData from '../hooks/useProductData'
import arrowRight from '../images/arrow_right.png'
import groupImg from '../images/group.svg'
import useDiagnostic from '../hooks/useDiagnostic'
import CouldntFind from '../components/couldntFind'
import { reportPCRequest } from '../utils/api'
import { openDTCModal } from "../store/application";
import userInfo from '../utils/userinfo'
import { useTranslation } from 'react-i18next'

const Title = styled.h6`
  padding-bottom: 5px;
  padding-top: 5px;
`

const ResolvedPC = () => {
  const dispatch = useDispatch()
  const { diagnosticItemsBySelectedSeries, getPCs } = useProductData()
  const { selectedSeries, selectedModel, selectedProduct, selectedRegion, selectedDiagnosticItems, setDtc, selectedHourMeter, selectedSerialNumber } = useDiagnostic()
  const [isLoading, setIsLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [isSent, setIsSent] = useState(false)
  const [reportData, setReportData] = useState({})
  const [drawerContent, setDrawerContent] = useState('list')
  const [activeSelector, setActiveSelector] = useState('')
  const [drawerData, setDrawerData] = useState([])
  const [selectedConcernPart, setSelectedConcernPart] = useState()
  const [selectedProductOfFailure, setselectedProductOfFailure] = useState()
  const [description, setDescription] = useState('')
  const { t } = useTranslation()

  // const userId = useSelector(state => state.user?.currentUser?._id)
  const user = useSelector(state => state.user?.currentUser)

  const downloadPdf = () => {
    html2PDF(document.getElementById('reportData'), {
      jsPDF: {
        format: 'a4',
      },
      margin: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20,
      },
      imageType: 'image/jpeg',
      output: `resoled_case_${R.pluck('label', selectedDiagnosticItems).join(',')}.pdf`
    })
  }

  const onOpenSelector = (selectorName: string) => {
    switch (selectorName) {
      case 'DTC':
        setDrawerContent('list')
        setDrawerData(R.differenceWith((option1: SelectData, option2: SelectData) => option1.value === option2.value, toOptions(diagnosticItemsBySelectedSeries, 'name', 'id'), selectedDiagnosticItems))
        break
      case 'Concerned Part':
        if (!selectedConcernPart?.value && selectedConcernPart?.label) setDrawerContent('manually')
        else setDrawerContent('list')
        setDrawerData(toOptions(R.uniqBy(R.prop('concernedPartResourceIdTranslation'), getPCs()), 'concernedPartResourceIdTranslation', 'concernedPartResourceId'))
        break
      case 'Type of Failure':
        if (!selectedProductOfFailure?.value && selectedProductOfFailure?.label) setDrawerContent('manually')
        else setDrawerContent('list')
        setDrawerData(toOptions(R.uniqBy(R.prop('typeOfFailureResourceIdTranslation'), getPCs()), 'typeOfFailureResourceIdTranslation', 'typeOfFailureResourceId'))
        break
    }
    setActiveSelector(selectorName)
    setOpen(true)
  }

  const onSelect = (item: any) => {
    switch (activeSelector) {
      case 'DTC':
        setDtc('add', item)
        setDrawerData(dtcs => dtcs.filter(dtc => dtc.value !== item.value))
        break
      case 'Concerned Part':
        setSelectedConcernPart(item)
        setOpen(false)
        break
      case 'Type of Failure':
        setselectedProductOfFailure(item)
        setOpen(false)
        break
    }
  }

  const onManuallyInput = (value) => {
    switch (activeSelector) {
      case 'Concerned Part':
        setSelectedConcernPart({ value: null, label: value })
        break
      case 'Type of Failure':
        setselectedProductOfFailure({ value: null, label: value })
        break
    }
  }

  const getManuallyValue = () => {
    switch (activeSelector) {
      case 'Concerned Part':
        return selectedConcernPart?.label
      case 'Type of Failure':
        return selectedProductOfFailure?.label
    }
  }

  const onTextInput = (value: string) => {
    setDescription(value)
  }

  const onRemoveDtc = dtc => {
    setDtc('remove', dtc)
  }

  const send = () => {
    const info = userInfo(user)
    setIsLoading(true)
    setIsSent(false)
    reportPCRequest({
      userId: user._id,
      firstName: info.firstName,
      lastName: info.lastName,
      email: info.email,
      company: info.company,
      dealerName: info.dealerName,
      date: new Date(),
      seriesId: selectedSeries.value,
      modelId: selectedModel?.value,
      regionId: selectedRegion.value,
      displayModelSetId: selectedProduct?.value,
      selectedDiagnosticItemss: R.pluck('value', selectedDiagnosticItems).join(','),
      concernedPartResourceId: selectedConcernPart?.value,
      concernedPartLabel: selectedConcernPart?.label,
      typeOfFailureResourceId: selectedProductOfFailure?.value,
      typeOfFailureLabel: selectedProductOfFailure?.label,
      causeDescription: description
    })
      .then(data => {
        setSelectedConcernPart(null)
        setselectedProductOfFailure(null)
        setDescription('')
        setReportData({
          selectedConcernPart,
          selectedProductOfFailure,
          description
        })
        toaster.push(<Message showIcon type="success">Request is sent</Message>)
      })
      .catch(error => toaster.push(<Message showIcon type="error"><pre>{error.toString()}</pre></Message>))
      .finally(() => {
        setIsLoading(false)
        setIsSent(true)
      })
  }

  return (
    <Grid>
    <SelectorDrawer isOpen={open} onClose={() => setOpen(false)} body={
      (drawerContent === 'list' &&
        <>
          <div style={{ height: '90%', marginBottom: '15px', overflow: 'auto' }}>
            <SelectorList withSublabel={false} searchPlaceHolder={`Find ${activeSelector}`} data={drawerData} onRowClick={onSelect} />
          </div>
          {(activeSelector === 'Concerned Part' || activeSelector === 'Type of Failure') && <CouldntFind label='Could not find?' subLabel='Add Manually' onClick={() => setDrawerContent('manually')} img={groupImg} />}
        </>)
      ||
      (drawerContent === 'manually' &&
        <FlexboxGrid style={{ flexDirection: 'column', height: '180px' }} justify='space-around' align='middle'>
          <FlexboxGrid.Item>
            <h6>Enter {activeSelector} manually</h6>
          </FlexboxGrid.Item>
          <FlexboxGrid.Item style={{ width: '100%' }}>
            <Input as='textarea' rows={3} placeholder='Enter an issue' onChange={onManuallyInput} value={getManuallyValue()} />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item>
            <Button onClick={() => setDrawerContent('list')}>Back to List</Button>
          </FlexboxGrid.Item>
        </FlexboxGrid>)
    }
    />
    <Card>
      <FlexboxGrid justify='space-between'>
        <FlexboxGrid.Item>
          <h6 style={{ padding: '20px' }}>{t('New quick report')}</h6>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item>
          {isSent && <h6 style={{ padding: '20px', cursor: 'pointer', color: '#009999' }} onClick={downloadPdf}>Download PDF</h6>}
        </FlexboxGrid.Item>
      </FlexboxGrid>
      <Panel bordered>
        <Row>
          <Col xs={24} sm={24} md={12} lg={12}>
            <Row>
              <div dangerouslySetInnerHTML={{__html: t('New quick report intro')}} />
              <Divider />
            </Row>
            <Row>
              <Col xs={16}>
                <Title>{t('Current DTCs')}</Title>
              </Col>
              <Col xs={8}>
                <ActionDiv onClick={() => onOpenSelector('DTC')}>{t('Add/Edit')} <img width={10} height={10} src={arrowRight} /></ActionDiv>
              </Col>
            </Row>
            <SelectorList
              data={selectedDiagnosticItems}
              withSearch={false}
              actionText={t('Remove')}
              onLabelClick={({ value }) => dispatch(openDTCModal(value))}
              onActionBtnClick={onRemoveDtc}
            />
            <Selector name='Concerned part' placeholder='concerned parts' onOpenSelector={onOpenSelector} value={selectedConcernPart?.label || ''} field='Concerned Part' />
            <Selector name='Type of failure' placeholder='type of failure' onOpenSelector={onOpenSelector} value={selectedProductOfFailure?.label || ''} field='Type of Failure' />
            <Input as='textarea' style={{ maxWidth: '350px' }} rows={6} placeholder='Briefly describe about the case' onChange={onTextInput} value={description} />
          </Col>
          {isSent &&
          <Col lg={12} md={12} sm={24} xs={24} id='reportData'>
            <Row style={{ textAlign: 'center' }}>
              <h6>{t('Kubota intelligent Diagnostics quick report')}</h6>
            </Row>
            <Row>
              <Col>
                <Panel>
                  <b>Series:</b> {selectedSeries.label}
                  <br/>
                  <b>Model Name:</b> {selectedModel.label}
                  <br/>
                  <b>Serial Number:</b> {selectedSerialNumber}
                  <br/>
                  <b>{t('Hour Meter')}: {selectedHourMeter}</b>
                </Panel>
              </Col>
            </Row>
            <Row>
              <Col>
                <Panel>
                  <b>DTCs:</b> {R.pluck('label', selectedDiagnosticItems).join(', ')}
                </Panel>
              </Col>
            </Row>
            <Row>
              <Col>
                <Panel>
                  <b>Cause:</b> {reportData?.selectedConcernPart?.label + ' ' + reportData?.selectedProductOfFailure?.label}
                </Panel>
              </Col>
            </Row>
            <Row>
              <Col>
                <Panel>
                  <b>Description:</b> {reportData?.description}
                </Panel>
              </Col>
            </Row>
          </Col>
          }
        </Row>
        {selectedConcernPart && selectedProductOfFailure &&
        <Row>
          <Divider />
          <Col xs={24} sm={24} md={16} lg={16}>
            {isLoading ? <Loader size='md' /> : <Button onClick={send}>Send Report</Button>}
          </Col>
        </Row>
        }
      </Panel>
    </Card>
  </Grid>)
}
export default ResolvedPC
