import React from 'react'
import { FlexboxGrid } from 'rsuite'

const InlineModal = ({ header = '', text = '', additional = null, buttons = [], style }) =>
  <div style={style}>
    <h6>{header}</h6>
    <br />
    <div>{text}</div>
    {additional && additional}
    <FlexboxGrid justify='space-around'>
      {buttons.map((btn, i) =>
        <FlexboxGrid.Item key={i}>{btn}</FlexboxGrid.Item>)
      }
    </FlexboxGrid>
  </div>

export default InlineModal
