import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: any = {
  history: []
}

export const historyDataSlice = createSlice({
  name: 'historyData',
  initialState,
  reducers: {
    setHistory: (state, { payload }: PayloadAction<any>) => {
      state.history = payload
    }
    // setHistory: (state, { payload }) => ({ ...payload })
  },
  extraReducers: (builder) => {
  }
})

export const { setHistory } = historyDataSlice.actions

export default historyDataSlice.reducer
