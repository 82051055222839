import {Row, Col, FlexboxGrid, Input, InputGroup} from 'rsuite'
import styled from "styled-components";
import React from "react";
import { glowingAnimation, glowingAnimationRed } from "./selector";
import { ValueTypeSelector } from "./causesAndTests"
import {isValueOk} from "../utils/convertUnits";
import useDiagnostic from "../hooks/useDiagnostic";
import { ssdbImage } from '../utils/fetcher';

const SDDBInput = styled(Input)`
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-name: ${({ isValid, isInvalid }) => isValid ? glowingAnimation : isInvalid ? glowingAnimationRed : 'none'};
`

const ValueConverter = ({ baseValue, testId, commonId, openedPC, getReferenceType, setValueType }) => {
  const valueType = getReferenceType(openedPC, testId, commonId) || baseValue
  console.log('getReferenceType', valueType, getReferenceType(openedPC, testId, commonId), openedPC, testId, commonId)
  switch (baseValue) {
    case 'MPa': {
      return (
        <FlexboxGrid style={{ margin: '8px 0' }} onClick={(e: any) => setValueType(testId, commonId, e)}>
          <FlexboxGrid.Item colspan={6}>
            <ValueTypeSelector isActive={valueType=== 'MPa'} value='MPa' first>MPa</ValueTypeSelector>
          </FlexboxGrid.Item>
          <FlexboxGrid.Item colspan={6}>
            <ValueTypeSelector isActive={valueType=== 'Bar'} value='Bar'>Bar</ValueTypeSelector>
          </FlexboxGrid.Item>
          <FlexboxGrid.Item colspan={6}>
            <ValueTypeSelector isActive={valueType=== 'PSI'} value='PSI'>PSI</ValueTypeSelector>
          </FlexboxGrid.Item>
          <FlexboxGrid.Item colspan={6}>
            <ValueTypeSelector isActive={valueType=== 'kgf/cm2'} value='kgf/cm2' last>kgf/cm2</ValueTypeSelector>
          </FlexboxGrid.Item>
        </FlexboxGrid>
      )
    }
    case 'kPa': {
      return (
        <FlexboxGrid style={{ margin: '8px 0' }} onClick={(e: any) => setValueType(testId, commonId, e)}>
          <FlexboxGrid.Item colspan={6}>
            <ValueTypeSelector isActive={valueType === 'kPa'} value='kPa' first>kPa</ValueTypeSelector>
          </FlexboxGrid.Item>
          <FlexboxGrid.Item colspan={6}>
            <ValueTypeSelector isActive={valueType === 'Bar'} value='Bar'>Bar</ValueTypeSelector>
          </FlexboxGrid.Item>
          <FlexboxGrid.Item colspan={6}>
            <ValueTypeSelector isActive={valueType === 'PSI'} value='PSI'>PSI</ValueTypeSelector>
          </FlexboxGrid.Item>
          <FlexboxGrid.Item colspan={6}>
            <ValueTypeSelector isActive={valueType === 'kgf/cm2'} value='kgf/cm2' last>kgf/cm2</ValueTypeSelector>
          </FlexboxGrid.Item>
        </FlexboxGrid>
      )
    }
    case 'mm': {
      return (
        <FlexboxGrid style={{ margin: '8px 0' }} onClick={(e: any) => setValueType(testId, commonId, e)}>
          <FlexboxGrid.Item colspan={6}>
            <ValueTypeSelector isActive={valueType === 'mm'}  value='mm' first>mm</ValueTypeSelector>
          </FlexboxGrid.Item>
          <FlexboxGrid.Item colspan={6}>
            <ValueTypeSelector isActive={valueType === 'in.'} value='in.' last>in.</ValueTypeSelector>
          </FlexboxGrid.Item>
        </FlexboxGrid>
      )
    }
    case 'L/min': {
      return (
        <FlexboxGrid style={{ margin: '8px 0' }} onClick={(e: any) => setValueType(testId, commonId, e)}>
          <FlexboxGrid.Item colspan={6}>
            <ValueTypeSelector isActive={getReferenceType(testId, commonId) === 'L/min'} first value='L/min'>L/min</ValueTypeSelector>
          </FlexboxGrid.Item>
          <FlexboxGrid.Item colspan={6}>
            <ValueTypeSelector isActive={getReferenceType(testId, commonId) === 'U.S. gals min'} value='U.S. gals min' >U.S. gals min</ValueTypeSelector>
          </FlexboxGrid.Item>
          <FlexboxGrid.Item colspan={6}>
            <ValueTypeSelector isActive={getReferenceType(testId, commonId) === 'Imp. gals min'} value='Imp. gals min' last>Imp. gals min</ValueTypeSelector>
          </FlexboxGrid.Item>
        </FlexboxGrid>
      )
    }
    default: return null
  }
}

const TableWrap = styled.div`
  table, th, td {
    border: 1px solid #ccc;
    padding: 5px;
  }
`

const optionHasValidation = option => option.min || option.max

const SSDBTable = ({ ssdbInfo = {}, testId, pcDetail }) => {
  const { openedPC, addTestReferenceValue, getReferenceValueById, getReferenceType }: any = useDiagnostic()

  const setValue = (testId: number, commonId: number, option: any, value: string) => {
    const type = getReferenceType(openedPC, testId, commonId) || option.unit?.name
    const isOk = isValueOk(value, option.min, option.max, option.value_number, option.unit?.name, type)?.isValid

   addTestReferenceValue(openedPC, testId, commonId, option.id, value, isOk, type)
  }

  const setValueType = (testId: number, commonId: number, e: any) => {
    const type = e.target.attributes.getNamedItem('value').value

    ssdbInfo.options?.forEach(opt => {
      const refValue = getReferenceValueById(testId, commonId, opt.id)
      const isOk = isValueOk(refValue?.value, opt.min, opt.max, opt.value_number, opt.unit?.name, type)?.isValid
      addTestReferenceValue(openedPC, testId, commonId, opt.id, refValue?.value, isOk, type)
    })
  }

  const categories = []

  for (const option of ssdbInfo.options) {
    const { category } = option
    if (category && category.id && (option.condition || option.pinsToBeTested) && !categories.find(_ => _.id === category.id)) categories.push(category)
  }

  const sortedCategories = categories.sort((a, b) => a.sort_order - b.sort_order)

  return (
    <div>
      <>
        <Row>
          <Col md={24}>
            {/*<h5 style={{ paddingBottom: 20 }}>Part: {ssdbInfo.partsNumber?.parts_number}</h5>*/}
            <TableWrap>
              <table style={{ width: '100%', textAlign: 'center' }}>
                <thead>
                <tr>
                  {ssdbInfo.connectorHarness && pcDetail.connectorHarness && <th>Connector (harness)</th>}
                  {ssdbInfo.connectorParts && pcDetail.connectorParts && <th>Connector (parts)</th>}
                  {ssdbInfo.connectorBothSides && pcDetail.connectorBoth && <th>Connector (both sides)</th>}
                </tr>
                </thead>
                <tbody>
                <tr>
                  {ssdbInfo.connectorHarness && ssdbInfo.connectorHarness.name && pcDetail.connectorHarness &&
                    <td>
                      <img style={{ maxWidth: '100%', margin: 'auto', display: 'block' }}
                           src={ssdbImage(ssdbInfo.connectorHarness.name)}
                           alt="Connector Harness"/>
                      { /* <img style={{ maxWidth: '100%', margin: 'auto', display: 'block' }}
                           src={`data:image/png;base64, ${ssdbInfo.connectorHarness?.image}`}
                           alt="Connector Harness"/> */ }
                    </td>
                  }
                  {ssdbInfo.connectorParts && ssdbInfo.connectorParts.name && pcDetail.connectorHarness &&
                    <td>
                      <img style={{ maxWidth: '100%', margin: 'auto', display: 'block' }}
                           src={ssdbImage(ssdbInfo.connectorParts.name)}
                           alt="Connector Harness"/>
                      { /* <img style={{ maxWidth: '100%', margin: 'auto', display: 'block' }}
                           src={`data:image/png;base64, ${ssdbInfo.connectorParts?.image}`}
                           alt="Connector Parts"/> */ }
                    </td>
                  }
                  {ssdbInfo.connectorBothSides && ssdbInfo.connectorBothSides.name && pcDetail.connectorBoth &&
                    <td>
                      <img style={{ maxWidth: '100%', margin: 'auto', display: 'block' }}
                           src={ssdbImage(ssdbInfo.connectorBothSides.name)}
                           alt="Connector Harness"/>
                      { /* <img style={{ maxWidth: '100%', margin: 'auto', display: 'block' }}
                           src={`data:image/png;base64, ${ssdbInfo.connectorBothSides?.image}`}
                           alt="Connector Both Sides"/> */ }
                    </td>
                  }
                </tr>
                </tbody>
              </table>
            </TableWrap>
            {
              pcDetail.referenceValue && sortedCategories.map(category =>
                (
                  <>
                    <h5 style={{ paddingTop: 10, paddingBottom: 10 }}>Category: {category.name}</h5>
                    <ValueConverter
                      baseValue={ssdbInfo?.options.find(opt => opt.unit?.name)?.unit?.name}
                      testId={testId}
                      openedPC={openedPC}
                      commonId={ssdbInfo.common_id}
                      setValueType={setValueType}
                      getReferenceType={getReferenceType}
                    />
                    <TableWrap>
                      <table style={{ width: '100%', }}>
                        <thead>
                        <tr>
                          {ssdbInfo?.options?.some(option => option.condition) && <th>Condition</th>}
                          {ssdbInfo?.options?.some(option => option.pinToBeTested) && <th>Pin to be tested</th>}
                          {ssdbInfo?.options?.some(option => option.pinInfoes) && <th>Pin info</th>}
                          {ssdbInfo?.options?.some(option => option.value_number || option.value_text) && <th>Value</th>}
                          {ssdbInfo?.options?.some(option => option.additional_info) && <th>Additional information</th>}
                          <th>Your results</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                          ssdbInfo?.options
                            ?.filter(option => option.category?.id === category.id)
                            .map((option, i) => (
                              <>
                                <tr key={i}>
                                  {ssdbInfo?.options?.some(option => option.condition) && <td>{option.condition}</td>}
                                  {ssdbInfo?.options?.some(option => option.pinToBeTested) && <td dangerouslySetInnerHTML={{ __html: option.pinsToBeTested.map((_: any) => _.name).join('<br />')}}></td>}
                                  {ssdbInfo?.options?.some(option => option.pinInfoes) && <td>{option.pinInfoes?.info}</td>}
                                  {ssdbInfo?.options?.some(option => option.value_text || option.value_number) && (
                                    <td>
                                      {option.value_text ||
                                        (!option.value_text && option.value_number
                                          && isValueOk(
                                            option.value_number,
                                            null,
                                            null,
                                            option.value_number,
                                            option.unit?.name,
                                            getReferenceType(openedPC, testId, ssdbInfo.common_id) || option.unit?.name
                                          )?.convertedStrictValue)
                                        && (getReferenceType(openedPC, testId, ssdbInfo.common_id) || option.unit?.name)}
                                    </td>
                                  )}
                                  {ssdbInfo?.options?.some(option => option.additional_info) && <td>{option.additional_info}</td>}
                                  <td>
                                    <InputGroup >
                                      {option?.unit?.name && <InputGroup.Addon> {getReferenceType(openedPC, testId, ssdbInfo.common_id) || option?.unit?.name}</InputGroup.Addon>}
                                      <SDDBInput
                                        value={getReferenceValueById(openedPC, testId, ssdbInfo.common_id, option.id)?.value}
                                        onChange={value => setValue(testId, ssdbInfo.common_id, option, value)}
                                        isValid={optionHasValidation(option) && getReferenceValueById(openedPC, testId, ssdbInfo.common_id, option.id)?.isOk}
                                        isInvalid={optionHasValidation(option) && !getReferenceValueById(openedPC, testId, ssdbInfo.common_id, option.id)?.isOk}
                                        type='number'
                                        step="0.01"
                                      />
                                    </InputGroup>
                                  </td>
                                </tr>
                              </>
                            ))
                        }
                        </tbody>
                      </table>
                    </TableWrap>
                  </>
                ))
            }
          </Col>
        </Row>
      </>
    </div>
  )
}

export default SSDBTable
