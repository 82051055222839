import { Col, Divider, Input, Panel, Row } from 'rsuite'
import { RenderDataInfo} from './index'
import { GreenGhostButton, RedGhostButton } from '../button'
import { TEST_RESULTS } from '../../utils/constants'
import React from 'react'
import { useTranslation } from 'react-i18next'
import FeedbackButton from "../feedbackButton";

const TestTab = ({ debug, openedPC, pcData, testData, geTestNotesByPCAndTestId, setTestNote, getTestAnswersByPCAndTestId, onAnswer }) => {
  const { t } = useTranslation()
  return (
    <div key={testData.id} style={{ borderLeft: '1px solid #e5e5ea' }}>
      <FeedbackButton pc={pcData} test={testData} />
      { debug && <pre>{JSON.stringify(testData, null, 2)}</pre> }
      <Panel>
        <b style={{ fontSize: '17px', marginBottom: '8px' }}  dangerouslySetInnerHTML={{__html: testData.testQuestionResourceIdTranslation }} />
        <p style={{ fontSize: '16px' }} dangerouslySetInnerHTML={{__html: testData.testDescriptionResourceIdTranslation }} />
      </Panel>
      <Divider style={{ margin: '0' }} />
      <RenderDataInfo additionalInfo={testData.additionalInfo} ssdbInfo={testData.ssdbInfo} linksImg={testData.linksImg} grsImg={testData.grsImg} testId={testData.id} pcId={openedPC} pcDetail={testData.pcDetailForTest} />
      <Divider style={{ margin: '0'}} />
      <Panel>
        <b>{t('Notes')}</b>
        <Input as='textarea'
               rows={3}
               placeholder={t('Enter Notes')}
               value={geTestNotesByPCAndTestId(openedPC, testData.id)?.notes}
               onChange={(value) => setTestNote(testData.id, value)}
        />
      </Panel>
      <Divider style={{ margin: '0'}} />
      <Panel>
        <Row>
          <Col xs={12} sm={8}>
            <GreenGhostButton
              isActive={getTestAnswersByPCAndTestId(openedPC, testData.id)?.result === TEST_RESULTS.PASS}
              onClick={() => onAnswer(TEST_RESULTS.PASS, testData)}
              width='100%'
            >
              {testData.passOutcomeLabelResourceIdTranslation}
            </GreenGhostButton>
          </Col>
          <Col xs={12} sm={8}>
            <RedGhostButton
              width='100%'
              onClick={() => onAnswer(TEST_RESULTS.TESTED, testData)}
            >
              {testData.failOutcomeLabelResourceIdTranslation}
            </RedGhostButton>
          </Col>
        </Row>
        <Row style={{ marginTop: 10 }}>
          <Row>
            {
              testData?.signals?.map(signal => {
              return (
                <Col xs={12} sm={8}>
                  <GreenGhostButton
                    width='100%'
                  >
                    Launch KOBD ACE function
                  </GreenGhostButton>
                </Col>
              )
            })
          }
          </Row>
          <Row>
            { /* <pre>{JSON.stringify(testData.signals, null, 2)}</pre> */ }
          </Row>
        </Row>
      </Panel>
      <br />
    </div>
  )
}

export default TestTab
