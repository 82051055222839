import { Input, Modal, Rate, Checkbox, Divider } from "rsuite";
import React, { useState, useEffect } from "react";
import Button, { SecondaryButton } from "./button";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {setRate} from "../store/diagnostic";

const useRate = () => {
  const dispatch = useDispatch()
  const feedback = useSelector(state => state.diagnostic.rate?.feedback || '')
  const points = useSelector(state => state.diagnostic.rate?.points)
  const sessionId = useSelector(state => state.user.sessionInProgress?._id)
  const userId = useSelector(state => state.user.currentUser._id)
  const company = useSelector(state => state.user.currentUser.company)
  const email = useSelector(state => state.user.currentUser.email)

  return {
    points,
    setPoints: points => dispatch(setRate({ points, sessionId, userId, company, email })),
    feedback,
    setFeedback: feedback => dispatch(setRate({ feedback, sessionId, userId, company, email })),
  }
}

export const RateInline = () => {
  const { t } = useTranslation()
  const { points, setPoints } = useRate()

  return (
    <div>
      {t`Was it helpful?`}
      <Rate style={{ marginLeft: 5 }} value={points} onChange={setPoints} size='xs' />
      {!points &&
        <div style={{ color: '#787878', textAlign: 'center', fontSize: 13 }}>
          {t`Please rate`}
        </div>
      }
    </div>
  )
}

const RateAppModal = ({ open }) => {
  const { t } = useTranslation()
  const [openRateAppModal, setOpenRateAppModal] = useState(false)
  const [dontShowAgain, setDontShowAgain] = useState(localStorage.getItem('showRateModal') === 'false')
  const {feedback, setFeedback, points, setPoints } = useRate()

  const send = () => {
    if (!feedback ) return
    setOpenRateAppModal(false)
  }

  const onClickCheckbox = checked => {
    setDontShowAgain(!checked)
    localStorage.setItem('showRateModal', checked ? 'false' : 'true')
  }

  useEffect(() => () => {
    setDontShowAgain(localStorage.getItem('showRateModal') === 'false')
    setFeedback('')
  }, [])

  useEffect(() => {
    setOpenRateAppModal(open)
  }, [open])

  return (
    <>
      <Modal open={openRateAppModal} backdrop='static'>
        <Modal.Header closeButton={false}>
          <h5>{t`Feedback`}</h5>
        </Modal.Header>
        <Modal.Body>
          <div>
            {t`Before you go, could you spare a moment to rate us? Your feedback is invaluable and helps us to improve your experience.`}
          </div>
          <Divider />
          <Rate defaultValue={3} value={points} onChange={setPoints} color='cyan' />
          <div style={{ padding: 10 }}/>
          <Input as='textarea'
                 required
                 value={feedback}
                 onChange={setFeedback}
                 maxLength={500}
                 rows={5}
          />
          <Checkbox checked={!dontShowAgain} onChange={(v, checked) => onClickCheckbox(checked)} />
          <span>{t`Dont ask again`}</span>
        </Modal.Body>
        <Modal.Footer style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button disabled={!feedback} width='200px' onClick={send}>Send</Button>
          <SecondaryButton width='200px' onClick={() => setOpenRateAppModal(false)}>Close</SecondaryButton>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default RateAppModal
