import { addToLog, setMemo } from '../store/diagnostic'

const writeActionsMiddleware = store => next => action => {
  const exceptActions = [
    addToLog.toString(),
    setMemo.toString()
  ]
  const toSave = ['diagnostic', '@@router'].some(reducerName => action.type.startsWith(reducerName)) && !exceptActions.includes(action.type)

  if (toSave) {
    store.dispatch(
      addToLog({
        date: new Date().getTime(),
        ...action
      })
    )
  }

  next(action)
}

export default writeActionsMiddleware
