import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Input, InputGroup, List, Panel, FlexboxGrid } from 'rsuite'
import SearchIcon from '@rsuite/icons/Search'
import arrowRight from '../images/arrow_right.png'
import { useTranslation } from 'react-i18next'

export const ActionDiv = styled.div`
  text-align: right;
  margin-right: 10px;
  color: #009999;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
  &:hover {
    text-shadow: 0px 1px 1px #444544;
  }
`
const LabelDiv = styled.div`
  cursor: pointer;
  width: fit-content;
  &:hover {
    text-shadow: 0px 1px 1px #444544;
  }
`

interface SelectorListProps {
  data?: SelectData[]
  onRowClick?: Function
  onLabelClick?: Function
  onActionBtnClick?: Function
  withSearch?: boolean
  withSublabel?: boolean
  actionText?: string,
  searchPlaceHolder?: string,
  active?: boolean
}

export interface SelectData {
  label: string,
  value: number | string,
  subLabel?: string,
  item?: any
}

export const ItemComponent = ({ label, id, value, subLabel, withSublabel, onLabelClick, onActionBtnClick, actionText, t }) => {
  return (
    <FlexboxGrid align='middle' style={{ height: '35px' }}>
      <FlexboxGrid.Item colspan={18}>
        <LabelDiv onClick={() => onLabelClick && onLabelClick({ label, value })}>
          <h6>{label}</h6>
          {withSublabel && <p>{subLabel || t('Details')}</p>}
        </LabelDiv>
      </FlexboxGrid.Item>
      <FlexboxGrid.Item colspan={6} onClick={() => onActionBtnClick && onActionBtnClick({ label, value })}>
        <ActionDiv>{
          actionText
            ? <div>{actionText}</div>
            : <img alt={actionText} src={arrowRight} width='10' height='10'/>
        }
        </ActionDiv>
      </FlexboxGrid.Item>
    </FlexboxGrid>
  );
}

const SelectorList = ({ data = [], onRowClick, onActionBtnClick, onLabelClick, withSublabel = true, withSearch = true, actionText = '', searchPlaceHolder = 'Find model' }: SelectorListProps) => {
  const [options, setOptions] = useState(data)
  const [searchValue, setSearchValue] = useState('')
  const { t } = useTranslation()

  const onSearch = (value: string) => {
    setSearchValue(value.trim())
    setOptions(data.filter((option: SelectData) => new RegExp(value.trim(), 'i').test(option.label)))
  }

  useEffect(() => {
    setOptions(data.filter((option: SelectData) => new RegExp(searchValue, 'i').test(option.label)))
  }, [data?.map((d) => d.value).join('')])

  return (
    <>
      {withSearch &&
      <InputGroup inside>
        <InputGroup.Button>
          <SearchIcon/>
        </InputGroup.Button>
        <Input placeholder={searchPlaceHolder} onChange={onSearch}/>
      </InputGroup>
      }
      <List size='md' style={{ marginTop: 10, /* height: '100%',*/ overflow: 'auto' }}>
        {options.map(({ value, label, subLabel, item }, index) => (
          <List.Item key={index} index={index} onClick={() => onRowClick && onRowClick({ label, value, item })} style={{ cursor: onRowClick ? 'pointer' : 'default' }}>
            <ItemComponent
              label={label}
              value={value}
              subLabel={subLabel}
              withSublabel={withSublabel}
              onLabelClick={onLabelClick}
              onActionBtnClick={onActionBtnClick}
              actionText={actionText}
              t={t}
            />
          </List.Item>
        ))}
      </List>
    </>
  )
}



export default SelectorList
