import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector} from 'react-redux'
import { FlexboxGrid, Modal } from 'rsuite'
import { useTranslation } from 'react-i18next'

import Button from './button'
import useProductData from '../hooks/useProductData'
import { closeDTCModal } from '../store/application'
import { splitWordByCapitalLetter, capitalizeFirstLetter } from '../utils'

const DtcDetailsModal = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const isOpen = useSelector(state => state.application.dtcModal.isOpened)
  const dtcId = useSelector(state => state.application.dtcModal.dtcId)

  const { getDtcDetails } = useProductData()
  const [dtc, setDtc] = useState({})

  useEffect(() => {
    setDtc(getDtcDetails(parseInt(dtcId)))
  }, [dtcId])

  return (
    <Modal backdrop='static' keyboard={false} open={isOpen} onClose={() => dispatch(closeDTCModal())}>
      <Modal.Header>
        <Modal.Title>
          <h6>DTC {t('Details')}</h6>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <>
          { dtc && Object.keys(dtc).map(key =>
            <div key={key}>
              <b>{splitWordByCapitalLetter(capitalizeFirstLetter(key).replace('ResourceIdTranslation', ''))}: </b><span dangerouslySetInnerHTML={{ __html: dtc[key] }} />
            </div>) }
        </>
      </Modal.Body>
      <FlexboxGrid style={{ marginTop: '20px' }} justify='center'>
        <FlexboxGrid.Item colspan={10}>
          <Button width='100%' onClick={() => dispatch(closeDTCModal())}>{t('Close')}</Button>
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </Modal>
  )
}

export default DtcDetailsModal
