import styled from 'styled-components'

const Card = styled.div<{ padding?: string }>`
  padding: ${({ padding }) => padding || 0}px;
  background-color: #fff;
  box-shadow: 0 4px 4px rgba(0,0,0,0.12), 0 0 10px rgba(0,0,0,0.06);
  border: 1px solid #e5e5ea;
  overflow: hidden;
`

export default Card
