import {localImagesCache} from "./constants";
import {sendPhoto} from "./api";

const syncLocalImages = async () => {
  const cacheImages = await caches.open(localImagesCache)
  const imagesKeys = await cacheImages.keys()
  console.log('syncLocalImages', imagesKeys)
  try {
    for (const imgKey of imagesKeys) {
      const url = new URL(imgKey.url).pathname
      const [, pcId, imageName] = url.split('/')
      const cachedResponse = await cacheImages.match(imgKey)

      if (pcId && imageName && cachedResponse) {
        const blob = await cachedResponse.blob()
        const formData = new FormData()

        formData.append('image', blob)
        formData.append('name', imageName)

        await sendPhoto(pcId, formData)
        await cacheImages.delete(imgKey)
      }
    }
  } catch (e) {
    console.error('syncLocalImages error', e)
  }
}

export { syncLocalImages }


