/* const languages = [
  { label: 'Japanese', code: 'jp', translation: '日本語' },
  { label: 'English', code: 'en', translation: 'English' },
  { label: 'French', code: 'fr', translation: 'Français' },
  { label: 'Spanish', code: 'es', translation: 'Español' },
  { label: 'German', code: 'de', translation: 'Deutsch' },
  { label: 'Chinese', code: 'zh', translation: '中文' },
  { label: 'Korean', code: 'ko', translation: '한국어' },
  { label: 'Italian', code: 'it', translation: 'Italiano' },
  { label: 'Dutch', code: 'nl', translation: 'Nederlands' },
  { label: 'Polish', code: 'pl', translation: 'Polskie' },
  { label: 'Portuguese', code: 'pt', translation: 'Português' }
] */

const languages = [
  {
    code: 'en-US',
    code2: 'EN',
    label: 'English',
    translation: 'English',
    interface: true
  },
  {
    code: 'fr-FR',
    code2: 'FR',
    label: 'French',
    translation: 'Français',
    interface: true
  },
  {
    code: 'de-DE',
    code2: 'DE',
    label: 'German',
    translation: 'Deutsch',
    interface: true
  },
  {
    code: 'it-IT',
    code2: 'IT',
    label: 'Italian',
    translation: 'Italiano',
    interface: true
  },
  {
    code: 'pt-PT',
    code2: 'PT',
    label: 'Portuguese',
    translation: 'Português',
    interface: true
  },
  {
    code: 'pl-PL',
    code2: 'PL',
    label: 'Polish',
    translation: 'Polskie',
    interface: true
  },
  {
    code: 'es-ES',
    code2: 'ES',
    label: 'Spanish',
    translation: 'Español',
    interface: true
  },
  {
    code: 'zh-CN',
    code2: 'ZH',
    label: 'Chinese',
    translation: '簡体字',
    interface: true
  },
  {
    code: 'nl-NL',
    code2: 'NL',
    label: 'Dutch',
    translation: 'Nederlands',
    interface: true
  },
  {
    code: 'ko-KR',
    code2: 'KO',
    label: 'Korean',
    translation: '한국어',
    interface: true
  },
  {
    code: 'ja-JP',
    code2: 'JP',
    label: 'Japanese',
    translation: '日本語',
    interface: true
  },
  {
    code: 'zh-TW',
    code2: 'TW',
    label: 'Taiwanese',
    translation: '繁体字',
    interface: true
  },
  {
    code: 'id-ID',
    code2: 'ID',
    label: 'Indonesian',
    translation: 'Bahasa Indonesia',
    interface: true
  },
  {
    code: 'tr-TR',
    code2: 'TR',
    label: 'Turkish',
    translation: 'Türk',
    interface: true
  }
]

export default languages
