import React from 'react'
import { Input, InputGroup } from 'rsuite'
import styled, { keyframes } from 'styled-components'
import arrowRight from '../images/arrow_right.png'
import { useTranslation } from 'react-i18next'

export const glowingAnimation = keyframes`
 0% { box-shadow: 0px 0px 1px 1px rgb(38 183 56 / 33%); }
 50% { box-shadow: 0px 0px 1px 1px rgb(38 183 56 / 90%); }
 100% { box-shadow: 0px 0px 1px 1px rgb(38 183 56 / 33%); }
`

export const glowingAnimationRed = keyframes`
 0% { box-shadow: 0px 0px 1px 1px rgb(255 0 1/ 33%); }
 50% { box-shadow: 0px 0px 1px 1px rgb(255 0 1 / 90%); }
 100% { box-shadow: 0px 0px 1px 1px rgb(255 0 1 / 33%); }
`

const SelectorWrap = styled.div<{ showCursor: boolean, disabled?: boolean, width?: string, isValid?: boolean, isInvalid?: boolean }>`
  cursor: ${props => props.showCursor ? 'pointer' : 'normal'};
  max-width: ${props => props.width ? props.width : '350px'};
  height: auto;
  min-height: 60px;
  filter: opacity(${({ disabled }) => disabled ? '0.5' : '1' });
  background: #F7F8FA;
  border: 1px solid #E0E0E0;
  box-sizing: border-box;
  border-radius: 4px;

  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 18px;
  padding-top: 10px;
  
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-name: ${({ isValid, isInvalid }) => isValid ? glowingAnimation : isInvalid ? glowingAnimationRed : 'none'};
`
const SelectorField = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 16px;
`
const SelectorValue = styled.div<{ selected: boolean}>`
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 22px;
  color: rgba(0, 0, 0, ${({ selected }) => selected ? 1 : 0.5 });
`
const RightArrow = styled.img`
  width: 10px;
  height: 10px;
  float: right;
  margin-right: 10px;
  margin-top: 12px;
`
const TransparentInput = styled(Input)`
  background: none;
  border: none;
  padding: 0;
  font-size: 17px;
  color: rgba(0, 0, 0, ${({ value }) => !!value ? 1 : 0.5 });
`

const Selector = ({ text, field, name, value = '', placeholder, onOpenSelector, onTextInput, width, disabled, addon, onlyNumbers, isValid }: any) => {
  const { t } = useTranslation()
  return (
    <SelectorWrap
        isValid={isValid}
        isInvalid={(typeof isValid !== 'undefined') && value && !isValid}
        disabled={disabled}
        width={width}
        showCursor={!text && !disabled}
        onClick={() => text ? false : !disabled && onOpenSelector(name)}
      >
        {!text && <RightArrow src={arrowRight}/>}
        <SelectorField>{field}</SelectorField>
        {
          text
            ? <InputGroup style={{ border: 'none' }}>
                <TransparentInput
                  type={onlyNumbers ? 'number' : 'text'}
                  step="0.01"
                  onChange={(val: string) => !disabled && onTextInput(name, val)}
                  value={value}
                  placeholder={t(`Enter ${placeholder}`)}
                  style={{ textAlign: addon ? 'right': 'left' }}
                />
                {addon && <InputGroup.Addon>{addon}</InputGroup.Addon>}
              </InputGroup>
            : <SelectorValue selected={!!value}>{ value ? value : t(`No ${placeholder} selected`) }</SelectorValue>
        }
      </SelectorWrap>
  )
}

export default Selector
