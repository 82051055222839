import * as R from 'ramda'
import { useDispatch, useSelector } from 'react-redux'
import { intervalToDuration } from 'date-fns'
import { persistor, RootState, store } from '../store/store'
import {
  setStartTime,
  setSSDBSeries,
  setSeries,
  setProduct,
  setSerial,
  addDtc,
  removeDtc,
  cleanAllDtc,
  resolveDiagnosticItem,
  setOpenedPC,
  setActiveDiagnosticItems,
  setOpenedTestRepairTab,
  setAnswerToQuestion,
  setTestReferenceValue,
  setTestAnswers,
  setTestNotes,
  setKeysToExpand,
  reInitiateDiagnostic,
  clean
} from '../store/diagnostic'
import { SelectData } from '../components/selectorlist'
import { push } from 'redux-first-history'

import { camelCaseToWords, capitalizeFirstLetter } from '../utils'
import { getDataByKey } from "./useProductData";
import {setSessionInProgress} from "../store/user";
import {TEST_RESULTS_FULLY} from "../utils/constants";

const useDiagnostic = () => {
  const dispatch = useDispatch()
  const startTime = useSelector((state: RootState) => state.diagnostic.startTime)
  const endTime = useSelector((state: RootState) => state.diagnostic.endTime)
  const selectedSeries = useSelector((state: RootState) => state.diagnostic.selectedSeries)
  const selectedSSDBSeries = useSelector((state: RootState) => state.diagnostic.selectedSSDBSeries)
  const selectedProduct = useSelector((state: RootState) => state.diagnostic.selectedProduct)
  const selectedSerialNumber = useSelector((state: RootState) => state.diagnostic.selectedSerialNumber)
  const selectedDiagnosticItems = useSelector((state: RootState) => state.diagnostic.selectedDiagnosticItems)
  const notResolvedDiagnosticItems = selectedDiagnosticItems.filter(di => di.status !== 'resolved')
  const resolvedDiagnosticItems = selectedDiagnosticItems.filter(di => di.status === 'resolved')
  const answersToQuestion = useSelector((state: RootState) => state.diagnostic.answersToQuestion)
  const openedPC = useSelector((state: RootState) => state.diagnostic.openedPC)
  const openedTestRepairTab = useSelector((state: RootState) => state.diagnostic.openedTestRepairTab)
  const referencesValues = useSelector((state: RootState) => state.diagnostic.testReferenceValues)
  const testAnswers = useSelector((state: RootState) => state.diagnostic.testAnswers)
  const testNotes = useSelector((state: RootState) => state.diagnostic.testNotes)
  const testPhotos = useSelector((state: RootState) => state.diagnostic.testPhotos)
  const memo = useSelector((state: RootState) => state.diagnostic.memo)
  const keysToExpand = useSelector((state: RootState) => state.diagnostic.keysToExpand)
  const lastLoadedOrSavedDiagnostic = useSelector((state: RootState) => state.diagnostic.lastLoadedOrSavedDiagnostic)
  const diagnosticTime = (endTime && startTime && intervalToDuration({ end: endTime, start: startTime })) || {}
  const logs = useSelector((state: RootState) => state.diagnostic.logs)
  const sortWeights = useSelector((state: RootState) => state.application.sortWeights)
  const initialData = useSelector((state: RootState) => state.diagnostic.initialData)
  const sessionInProgress = useSelector((state: RootState) => state.user?.sessionInProgress)

  const getQuestionAnswer = (pcId: number, questionId: number) => answersToQuestion.find((aq) => aq.pcId === pcId && aq.questionId === questionId)
  const getTestAnswersByPCAndTestId = (pcId: number, testId: number) => testAnswers.find((ta: any) => ta.testId === testId && ta.pcId === pcId)
  const geTestNotesByPCAndTestId = (pcId: number, testId: number) => testNotes.find((ta: any) => ta.testId === testId && ta.pcId === pcId)
  const geTestPhotos = (pcId: number) => testPhotos.find((tp: any) => tp.pcId === pcId)?.photos || []
  const getTestResultByPc = (pcId: number) => testAnswers.find((ta: any) => ta.pcId === pcId && ta.result)
  const isTestPassedByResolvedDiagnosticItem = (diId: number) => resolvedDiagnosticItems.find(resolvedDI => resolvedDI.id === diId)

  const isPCFullyTested = (pcId: number, relatedSdiId: number) =>
    Object.values(TEST_RESULTS_FULLY).includes(getTestResultByPc(pcId)?.result)
      || !!isTestPassedByResolvedDiagnosticItem(relatedSdiId)

  const setSelector = (type: string, value: any) => {
    switch (type) {
      case '_s_s_d_b_series': {
        console.log('setSelector :: ssdb series', value)
        dispatch(setProduct(null))
        selectedDiagnosticItems.length && dispatch(cleanAllDtc())
        dispatch(setSSDBSeries(value))
        // const series = getDataByKey('series').find(s => s.id === value.value)
        const series = getDataByKey('series').find(s => s.id === value.item?.seriesId) // KID-97
        if (series) dispatch(setSeries({ label: series.name, value: series.id }))
        else dispatch(setSeries(null))
        break
      }
      case 'product_group': {
        console.log('v', value)
        dispatch(setProduct(value))
        break
      }
      case 'serialNumber': return dispatch(setSerial(value))
    }
  }
  const setDtc = (action: string, item: SelectData) => {
    switch (action) {
      case 'add':
        return dispatch(addDtc(item))
      case 'remove':
        return dispatch(removeDtc(item))
    }
  }

  const setInitialData = data => dispatch(setInitialData(data))

  const addAnswerToQuestion = (pcId: number, questionId: number, questionLabel: string, button: string, buttonLabel: string) => dispatch(setAnswerToQuestion({ pcId, questionId, questionLabel, button, buttonLabel }))
  const addTestReferenceValue = (pcId: number, testId: number, commonId: number, optionId: number, value: number, isOk: boolean, type: string) => dispatch(setTestReferenceValue({ pcId, testId, commonId, optionId, value, isOk, type }))
  const getReferenceValueById = (pcId: number, testId: number, commonId: number, optionId: number) => referencesValues.find((rf: any) => rf.pcId === pcId && rf.testId === testId && rf.commonId === commonId && rf.optionId === optionId)
  const getReferenceType = (pcId: number, testId: number, commonId: number) => referencesValues.find((rf: any) => rf.pcId === pcId && rf.testId === testId && rf.commonId === commonId)?.type

  const cleanDiagnostic = () => {
    dispatch(reInitiateDiagnostic())
  }

  const startDiagnostic = (startFrom: string) => {
    dispatch(setStartTime(new Date().getTime()))
    dispatch(push(startFrom))
  }

  const saveDiagnostic = async () => {
    await persistor.purge()
    store.dispatch(push('/selectsModels'))
    store.dispatch(clean())
  }

  const getTimeLineData = () => {
    const actionsNames = [
      'diagnostic/setSeries',
      'diagnostic/setModel',
      'diagnostic/setProduct',
      'diagnostic/addDtc',
      'diagnostic/removeDtc',
      'diagnostic/resolveDiagnosticItem',
      'diagnostic/setTestReferenceValue',
      'diagnostic/setTestAnswers'
    ]

    const getReadableDesc = (actionType, payload) => {
      if (!payload) return ''
      switch (actionType) {
        case 'diagnostic/setSeries':
        case 'diagnostic/setProduct':
        case 'diagnostic/addDtc':
        case 'diagnostic/removeDtc':
          return payload.label
        case 'diagnostic/resolveDiagnosticItem':
          return `${payload.label} Cause: ${payload.pcConcernedPart}`
        case 'diagnostic/setTestAnswers':
          const { testLabel, resultLabel } = getTestAnswersByPCAndTestId(payload.pcId, payload.testId)
          return `${testLabel || 'NONE'} ${resultLabel || 'NONE'}`
        case 'diagnostic/setTestReferenceValue':
          const { value, type } = payload
          return `${value} ${type}`
        default: return 'No Description'
      }
    }

    const actionsToDisplay = logs.filter(({ type }) => actionsNames.includes(type))

    return actionsToDisplay.map((log, i) => {
      const selectedProduct = actionsToDisplay.slice(0, i + 1).filter(({ type }) => type === 'diagnostic/setProduct').reverse()?.[0]

      return {
          date: new Date(log.date).toLocaleDateString() + '  '  + new Date(log.date).toLocaleTimeString(),
          product: selectedProduct?.payload?.label || 'NOT SELECTED',
          action: capitalizeFirstLetter(camelCaseToWords(log.type)),
          description: getReadableDesc(log.type, log.payload)
        }
      })
  }

  return {
    clean,
    startDiagnostic,
    cleanDiagnostic,
    startTime,
    diagnosticTime,
    selectedSSDBSeries,
    selectedSeries,
    selectedProduct,
    selectedSerialNumber,
    selectedDiagnosticItems,
    selectedDiagnosticItemsIds: R.pluck('id', selectedDiagnosticItems),
    notResolvedDiagnosticItems,
    resolvedDiagnosticItems,
    answersToQuestion,
    openedPC,
    testAnswers,
    memo,
    lastLoadedOrSavedDiagnostic,
    openedTestRepairTab,
    getTestAnswersByPCAndTestId,
    getQuestionAnswer,
    geTestPhotos,
    geTestNotesByPCAndTestId,
    getReferenceValueById,
    getReferenceType,
    isTestPassedByResolvedDiagnosticItem,
   // getTestNamesAndResults,
    getTimeLineData,
    getTestResultByPc,
    setSelector,
    setDtc,
    resolveDiagnosticItem: item => dispatch(resolveDiagnosticItem(item)),
    addAnswerToQuestion,
    addTestReferenceValue,
    setOpenedPC,
    setActiveDiagnosticItems,
    setOpenedTestRepairTab,
    setTestAnswers,
    setTestNotes,
    keysToExpand,
    setKeysToExpand: keys => dispatch(setKeysToExpand(keys)),
    saveDiagnostic,
    sortWeights,
    initialData,
    setInitialData: data => dispatch(setInitialData(data)),
    sessionInProgress,
    isPCFullyTested
  }
}

export const restartDiagnostic = async ({ initialData = {}}: any = {}) => {
  await persistor.purge()
  await store.dispatch(push('/selectModels'))
  store.dispatch(clean())
  store.dispatch(setSessionInProgress (null))
  if (initialData && initialData.dtc) {
    await store.dispatch(addDtc(initialData.dtc))
  }
}

export default useDiagnostic
