import { useEffect, useState } from "react"
import useDiagnostic from "../hooks/useDiagnostic"
import useProductData from "../hooks/useProductData"
import { LinkButton } from "./causesAndTests"

import { Loader, Popover, Whisper } from "rsuite"

const Links = ({ ssdbInfo }) => {
  const { selectedProduct } = useDiagnostic()
  const { getDataByKey } = useProductData()
  const wsmLinks = getDataByKey('wsm_link') || []
  const wsmLink = wsmLinks.find((_: any) => _.id === selectedProduct?.item?.wsmLinkId)
  const wsmLinkNumber = wsmLink?.number
  const [loading, setLoading] = useState(true)
  const [linkData, setLinkData] = useState([])

  let wsmGuids = []

  if (ssdbInfo && ssdbInfo.wsm_guids && ssdbInfo.wsm_guids.length > 0 ) wsmGuids = ssdbInfo.wsm_guids.split(',') // .split(/\r?\n/).split(',')

  wsmGuids = wsmGuids.map(_ => _.trim())

  useEffect(() => {
    const fetchLinkData = async () => {
      setLoading(true)
      try {


        const _linkData = []

        for (const guid of wsmGuids) {
          const fullWsmLink = `https://kits4.kubota.co.jp/html/${wsmLinkNumber}HTML.zip_unzipped/${guid}.html`
          const result = await fetch(fullWsmLink, { credentials: 'include'})
          const htmlResult = await result.text() 
          const parser = new DOMParser()
          const doc = parser.parseFromString(htmlResult, 'text/html')
          console.log('doc', doc)
          const title = doc.querySelectorAll('title')[0]
          console.log('title', title.innerText)
          _linkData.push({
            title: title.innerText,
            link: fullWsmLink
          })
        }

        setLinkData(_linkData)
      } catch (e) {
        console.error(e)
      } finally {
        setLoading(false)
      }
    }
    fetchLinkData()
  }, [ssdbInfo])

  if (loading) return <Loader size='md' />
  
  return (
      <>
        <h5>WSM Links</h5>
        <ul>
        {
          linkData && linkData.map(link => {
            return <li><a href={link.link} target='_blank'>{link.title}</a></li>
          })
        }
        </ul>
      </>
  )
}
const WsmLinkViewer = ({ ssdbInfo, disabled }) => {
  if (disabled) return <LinkButton disabled>WSM</LinkButton>
  return (
    <Whisper trigger='click' speaker={<Popover><Links ssdbInfo={ssdbInfo} /></Popover>}>
      <LinkButton>WSM</LinkButton>
      </Whisper>
  )
}

export default WsmLinkViewer