const userInfo = (user: any) => {
  if (user.session && user.session.otherData && user.session.otherData.dealerName) {
    return {
      firstName: '',
      lastName: '',
      email: user.session.otherData.dealerEmail,
      company: user.session.otherData.accessSource,
      dealerName: user.session.otherData.dealerName
    }
  }
  console.log('no otherdata')
  return {
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    company: user.company
  }
}

export default userInfo
