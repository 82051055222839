import React, {useEffect, useState} from 'react'
import { useTranslation } from 'react-i18next'
import { pick } from 'ramda'
import { Grid, Panel, Row, Col, FlexboxGrid, Divider } from 'rsuite'
import FileDownloadIcon from '@rsuite/icons/FileUpload'
import {useDispatch, useSelector} from 'react-redux'
import Hotkeys from 'react-hot-keys'

import Button, { SecondaryButton } from '../components/button'
import Card from '../components/card'

import useDiagnostic, {restartDiagnostic} from '../hooks/useDiagnostic'
import useProductData from '../hooks/useProductData'
import useNavigation from '../hooks/useNavigation'
import PCList from '../components/pcList'
import SelectorDrawer from '../components/selectordrawer'
import {NavBtn, SelectedValueWrapper} from '../components/navComponents'
import { resolveDiagnosticItem, setPcList, cleanAnswers } from '../store/diagnostic'
import {
  filterChildrenAndRoot,
  findElementById,
  findIdBeforeId,
  flatten,
  removeChildById,
  traverseAndModify
} from "../utils"
import {DRAWER_BODY, SESSION_STATUS, TEST_RESULTS} from '../utils/constants'
import { openDTCModal } from "../store/application";

import {
  DrawerBodyContent,
  PcListMobileLink,
} from '../components/causesAndTests'
import RepairTab from "../components/causesAndTests/repairTab";
import TestTab from "../components/causesAndTests/testTab";
import useHistoryData, {saveSessionStateToDb} from "../hooks/useHistoryData";
import PdfReport from "../components/pdfReport";
import {finishSession} from "../store/user";
import RateAppModal from "../components/rateAppModal";

const CausesTests = () => {
  const fullState = useSelector(state => state)
  const isOnline = useSelector(state => state.application.connectionStatus) === 'online'
  const sessionInProgress = useSelector(state => state.user?.sessionInProgress)
  const user = useSelector(state => state.user)
  const configs = useSelector(state => state.application.configs)
  const {
    openedPC, openedTestRepairTab, setOpenedTestRepairTab, selectedSerialNumber, setActiveDiagnosticItems,
    setOpenedPC, setTestAnswers, getTestAnswersByPCAndTestId, geTestNotesByPCAndTestId, selectedDiagnosticItemsIds,
    setTestNotes, getTestResultByPc, notResolvedDiagnosticItems, setDtc, isPCFullyTested,
    testAnswers, keysToExpand, setKeysToExpand
  }: any = useDiagnostic()
  const {  width } = useNavigation()
  const {
    getPcListByDiagnosticItems, getTestForPc, diagnosticItemsTreeWithPc,
    getRepairInfoByPcId, diagnosticItemsBySelectedSeries
  } = useProductData()
  const { exportDiagnosticJSON } = useHistoryData()

  const dispatch = useDispatch()
  const { t, i18n } = useTranslation()
  const [openRateAppModal, setOpenRateAppModal] = useState(false)
  const [open, setOpen] = useState<any>(false)
  const [debug, setDebug] = useState<any>(false)
  const [drawerBody, setDrawerBody] = useState<any>(DRAWER_BODY.ASK)
  const [areAllPcResolved, setAreAllPcResolved] = useState(false)
  const [areDIResolved, setAreDIResolved] = useState(false)
  const [testData, setTestData] = useState<any>([])
  const [repairInfo, setRepairInfo] = useState<any>({})
  const [unusedDiagnosticItems, setUnusedDiagnosticItems] = useState(diagnosticItemsBySelectedSeries.map(di => filterChildrenAndRoot(di, selectedDiagnosticItemsIds)).filter(s => !!s))

  const pcBySelectedSeriesAndDTCs = getPcListByDiagnosticItems(null)

  const isSessionFinished = sessionInProgress?.meta?.status === SESSION_STATUS.FINISHED

  const findUnresolvedPcNode = pcNode => {
    if (!pcNode) return null

    const hasTest = !!getTestForPc(pcNode.pcId).length
    const resolved = getTestResultByPc(pcNode.pcId)?.result

    if (!hasTest && pcNode.children?.length) {
      return openChildPc(pcNode.pcId)
    } else if (hasTest && !resolved) {
      return pcNode.pcId
    }
  }

  const isPcResolved = pc => !pc.pcDetail?.testId ? true : isPCFullyTested(pc.pcId, pc.sdi?.id)

  const kitsUrl = () => {
    console.log('kitsUrl')
    try {
      debugger
      let link = ''
      const userRoles = [...user.currentUser.kitsRoles, ...user.currentUser.kiscRoles]
      const inquiryLinks = JSON.parse(configs.find(_ => _.key === 'inquiryLinks').value)
      
      const matchingRole = inquiryLinks.find(_ => _.roles.find(role => userRoles.includes(role)))
      console.log('matchingRole', matchingRole)
      if (matchingRole) link = matchingRole.link
      else {
        const defaultLink = inquiryLinks.find(_ => _.default)
        if (defaultLink) link = defaultLink.link
      }

      if (link) {
        if (link.includes('?')) return link + `&kid=${sessionInProgress._id}`
        else return link += `?kid=${sessionInProgress._id}`
      }

      return `https://kits.intra.kbt-global.com/new?technicalinquiry=&kid=${sessionInProgress._id}`
    } catch (e) {
      console.error(e)
      return `https://kits.intra.kbt-global.com/new?technicalinquiry=&kid=${sessionInProgress._id}`
    }
      
  }

  const checkPCsWith = fn => pcBySelectedSeriesAndDTCs
    .map(rootPc => {
      let resolved = []
      traverseAndModify(rootPc, pc => {
        console.log('checkPCsWith', pc, fn(pc), !!pc.pcDetail?.testId,  isPCFullyTested(pc.pcId, pc.sdi?.id))
        if (fn(pc)) {
          resolved.push(true)
        } else {
          resolved.push(false)
        }
      })
      return resolved
    })
    .flat()
    .every(r => r)

  const getNonAnsweredChildPcId = parentPcNode => parentPcNode.children.find(pc => !getTestResultByPc(pc.pcId))?.pcId

  const getNotAnsweredPC = (pcId = null) => {
    let pcNode

    const areAllAnswered = pcBySelectedSeriesAndDTCs
      .map(rootPc => {
        let resolved = []
        traverseAndModify(rootPc, pc => {
            if (getTestResultByPc(pc)) {
              resolved.push(true)
            } else {
              resolved.push(false)
            }
        })
        return resolved
      })
      .flat()
      .every(r => r)

    if (areAllAnswered) {
      return null
    }

    if (pcId) {
      const currentPcNode = getPcNodeById(pcId)
      const selectedNodeRoot = diagnosticItemsTreeWithPc.find(di => di.rootId === currentPcNode.rootId)
      const parentPcId = findIdBeforeId(selectedNodeRoot, currentPcNode.pcId, 'pcId').map(v => v.id)?.[0]
      const parentPcNode = getPcNodeById(parentPcId)
      if (parentPcNode?.children?.length) {
        const nonAnsweredPcId = getNonAnsweredChildPcId(parentPcNode)
        if (nonAnsweredPcId) {
          return nonAnsweredPcId
        }
      }
    }

    for (pcNode of pcBySelectedSeriesAndDTCs) {
      const unresolvedPc = findUnresolvedPcNode(pcNode)
      console.log('getNotAnsweredPC findUnresolvedPcNode', unresolvedPc)
      if (unresolvedPc) return unresolvedPc
    }

    return null
  }

  const getPcNodeById = pcId => {
    if (!pcId) return  {}

    return pcBySelectedSeriesAndDTCs.reduce((acc, rootPc) => {
      const foundPc = findElementById(rootPc, pcId, 'pcId')
      if (foundPc) {
        acc = foundPc
      }
      return acc
    }, {})
  }

  const openChildPc = openedPC => {
    const currentPcNode = getPcNodeById(openedPC)
    const selectedNodeRoot = diagnosticItemsTreeWithPc.find(di => di.rootId === currentPcNode.rootId)
    const childPcs = getPcListByDiagnosticItems(currentPcNode.rootId, currentPcNode.id)
    const nonAnsweredChild = childPcs.filter(pc => !getTestResultByPc(pc.pcId)?.result)

    if (nonAnsweredChild.length) {
      const idsToExpand = findIdBeforeId(selectedNodeRoot, childPcs[0].pcId, 'pcId').map(({ id }) => id)

      setKeysToExpand(idsToExpand)

      dispatch(setOpenedPC(nonAnsweredChild[0].pcId))
      dispatch(setOpenedTestRepairTab('test'))
      return nonAnsweredChild[0].pcId
    } else {
      return null
    }
  }

  const resolveParentPcByChild = currentPcNode => {
    const selectedNodeRoot = diagnosticItemsTreeWithPc.find(di => di.rootId === currentPcNode.rootId)
    const parentPcId = findIdBeforeId(selectedNodeRoot, currentPcNode.pcId, 'pcId').map(v => v.id)?.[0]
    const pc = findElementById(selectedNodeRoot, parentPcId, 'pcId')

    if (pc?.children?.length) {
      const allChildPcHaveAnswers = pc.children.every(pc => getTestResultByPc(pc.pcId))

      if (allChildPcHaveAnswers) {
        const allChildPcHaveAnswersPass = pc.children.every(pc => getTestResultByPc(pc.pcId).result === TEST_RESULTS.PASS)
        const allChildPcHaveAnswersFail = pc.children.every(pc => getTestResultByPc(pc.pcId).result === TEST_RESULTS.FAIL)
        const conductRepair = pc.children.some(pc => getTestResultByPc(pc.pcId).result === TEST_RESULTS.CONDUCT_REPAIR)

        if (allChildPcHaveAnswersPass)
          setTestResult(pc.pcId, 0, testData.testQuestionResourceIdTranslation, TEST_RESULTS.PASS, testData.passOutcomeLabelResourceIdTranslation)
        else if (allChildPcHaveAnswersFail)
          setTestResult(pc.pcId, 0, testData.testQuestionResourceIdTranslation, TEST_RESULTS.FAIL, testData.passOutcomeLabelResourceIdTranslation)
        else if (conductRepair)
          setTestResult(pc.pcId, 0, testData.testQuestionResourceIdTranslation, TEST_RESULTS.CONDUCT_REPAIR, testData.passOutcomeLabelResourceIdTranslation)
        else
          setTestResult(pc.pcId, 0, testData.testQuestionResourceIdTranslation, TEST_RESULTS.TESTED, testData.passOutcomeLabelResourceIdTranslation)
      }
    }
  }

  const openNextPc = () => {
    const nextPc = getNotAnsweredPC(openedPC)
    dispatch(setOpenedTestRepairTab('test'))
    dispatch(setOpenedPC(nextPc))
    return nextPc
  }

  const onOpenPC = ({ value }: any) => {
    console.log('onOpenPC', value)
    const testData = getTestForPc(value)
    const currentPcNode = getPcNodeById(value)
    console.log('currentPcNode', currentPcNode, testData)
    if (!testData.length) {
      if (currentPcNode.children?.length) {
        openChildPc(value)
      }
    } else {
      setTestData(testData)
      dispatch(setOpenedPC(value))
      dispatch(setOpenedTestRepairTab('test'))
    }
  }

  const isTestFail = () => getTestResultByPc(openedPC)?.result === TEST_RESULTS.TESTED

  const onOpenRepairTab = () => {
    dispatch(setOpenedTestRepairTab('repair'))
  }

  const onAnswer = (result: string, testData: any) => {
    setTestResult(openedPC, testData.id, testData.testQuestionResourceIdTranslation, result, testData.passOutcomeLabelResourceIdTranslation)

    if (result == TEST_RESULTS.TESTED) {
      return dispatch(setOpenedTestRepairTab('repair'))
    }

    if (result === TEST_RESULTS.FAIL) {
      const currentPcNode = getPcNodeById(openedPC)
      if (currentPcNode?.children?.length) {
        openChildPc(openedPC)
      } else {
        openNextPc()
      }
    } else {
      openNextPc()
    }
  }
  console.log('testData', testData)
  const onResolveDiagnosticItem = item => {
    const test = testData?.[0]
    const currentPcNode = getPcNodeById(openedPC)
    dispatch(resolveDiagnosticItem({ ...item, pcId: openedPC, pcConcernedPart: `${currentPcNode.pc.concernedPartResourceIdTranslation} ${currentPcNode.pc.typeOfFailureResourceIdTranslation}` }))
    setUnusedDiagnosticItems(diagnosticItemsBySelectedSeries.map(di => filterChildrenAndRoot(di, selectedDiagnosticItemsIds)).filter(s => !!s))
    setTestResult(openedPC, test.id, test.testQuestionResourceIdTranslation, TEST_RESULTS.CONDUCT_REPAIR, test.passOutcomeLabelResourceIdTranslation)
  }

  const resolveRemainsDTCs = () => {
    const currentPcNode = getPcNodeById(openedPC)
    const test = testData?.[0]
    setTestResult(openedPC, test.id, test.testQuestionResourceIdTranslation, TEST_RESULTS.CONDUCT_REPAIR, test.passOutcomeLabelResourceIdTranslation)
    notResolvedDiagnosticItems.map(di => dispatch(resolveDiagnosticItem({ ...di, pcId: currentPcNode.pcId, pcConcernedPart: `${currentPcNode.pc.concernedPartResourceIdTranslation} ${currentPcNode.pc.typeOfFailureResourceIdTranslation}` })))
  }

  const onAddToList = item => {
    setDtc('add', item)
    setUnusedDiagnosticItems(prevState =>
      !item.rootId
        ? prevState.filter(option => option.value !== item.value)
        : removeChildById(prevState, item.value, 'value')
    )
  }

  const setTestResult = (pcId: number, testId: number, testLabel: string, result: string, resultLabel: string) => {
    dispatch(setTestAnswers({ pcId, testId, testLabel, result, resultLabel }))
  }

  const setTestNote = (testId: number, notes: string) => {
    dispatch(setTestNotes({ pcId: openedPC, testId, notes }))
  }

  const goToRepairTabWithoutTest = () => {
    dispatch(setOpenedTestRepairTab('repair'))
    setOpen(false)
  }

  const onConductRepair = () => {
    setDrawerBody(DRAWER_BODY.ASK)
    setOpen(true)
  }

  useEffect(() => {
    pcBySelectedSeriesAndDTCs.forEach(rootPc => {
      traverseAndModify(rootPc, pc => {
        resolveParentPcByChild(pc)
      })
    })
    if (!notResolvedDiagnosticItems.length) setAreDIResolved(true)
    else  setAreDIResolved(false)

    const arePCresolved = checkPCsWith(isPcResolved)
    console.log('arePCresolved', arePCresolved)
    if (arePCresolved) setAreAllPcResolved(true)
    else setAreAllPcResolved(false)
  },
    [JSON.stringify(testAnswers), notResolvedDiagnosticItems.length]
  )

  // open next PC
  useEffect(() => {
    console.log('11111', getTestResultByPc(openedPC)?.result === TEST_RESULTS.TESTED,  drawerBody === DRAWER_BODY.PARTIALLY, drawerBody )
    if (getTestResultByPc(openedPC)?.result === TEST_RESULTS.TESTED || drawerBody === DRAWER_BODY.PARTIALLY) return
    console.log('11111 getNotAnsweredPC()', getNotAnsweredPC())
    openNextPc()
  }, [JSON.stringify(testAnswers), open])

  useEffect(() => {
    if (openedPC) {
      const currentPcNode = getPcNodeById(openedPC)
      const constSDIids = currentPcNode.roots?.map(({ sdi }) => sdi.id)
      setTestData(getTestForPc(openedPC))
      setRepairInfo(getRepairInfoByPcId(openedPC))
      dispatch(setActiveDiagnosticItems(constSDIids))
    } else {
      openNextPc()
    }
  }, [openedPC])

  useEffect(() => {
    if ((areDIResolved || areAllPcResolved) && !isSessionFinished) {
      dispatch(finishSession())

      if (isOnline) setTimeout(() => setOpenRateAppModal(true), 3000)

      saveSessionStateToDb(fullState, SESSION_STATUS.FINISHED)
    }
  }, [areDIResolved])


  useEffect(() => {
    if (openedPC) {
      setTestData(getTestForPc(openedPC))
      setRepairInfo(getRepairInfoByPcId(openedPC))
    }
    return () => {
      setOpenedPC(null)
      setTestData(null)
      setRepairInfo({})
    }
  }, [i18n.language])

  useEffect(() => {
    dispatch(
      setPcList(
        flatten(pcBySelectedSeriesAndDTCs).map(pc => pick(['id', 'parent', 'pcId', 'pc', 'sdi'], pc))
      )
    )
  },
    [selectedDiagnosticItemsIds.join(',')]
  )

  const isFullDrawer = width < 992 && (drawerBody === DRAWER_BODY.PARTIALLY || drawerBody === DRAWER_BODY.PC_LIST)
  const disabledInterface = areDIResolved || areAllPcResolved

  return (
    <Hotkeys
      keyName='alt+d'
      onKeyUp={() => setDebug(!debug)}
    >
      {debug && <Button onClick={() => dispatch(cleanAnswers())}>clean</Button>}
      <RateAppModal open={openRateAppModal} />
      <Grid style={{ height: '100%' }}>
        <SelectorDrawer
          isOpen={open}
          onClose={() => {
            setOpen(false)
            setDrawerBody(DRAWER_BODY.ASK)
          }}
          closeButton={drawerBody !== DRAWER_BODY.ASK}
          isFull={isFullDrawer}
          size={drawerBody === DRAWER_BODY.PC_LIST ? 'lg' :'xs' }
          body={
            <DrawerBodyContent
              disabled={disabledInterface}
              drawerBody={drawerBody}
              testData={testData}
              notResolvedDiagnosticItems={notResolvedDiagnosticItems}
              openedPC={openedPC}
              pcBySelectedSeriesAndDTCs={pcBySelectedSeriesAndDTCs}
              unusedDiagnosticItems={unusedDiagnosticItems}
              keysToExpand={keysToExpand}
              setDrawerBody={setDrawerBody}
              setOpen={setOpen}
              setOpenedTestRepairTab={setOpenedTestRepairTab}
              onAnswer={onAnswer}
              onOpenPC={onOpenPC}
              onAddToList={onAddToList}
              onResolveDiagnosticItem={onResolveDiagnosticItem}
              resolveRemainsDTCs={resolveRemainsDTCs}
              goToRepairTabWithoutTest={goToRepairTabWithoutTest}
            />
        }
        />
        <Card style={{ height: '100%' }}>
          <Row gutter={0} style={{ height: '100%' }}>
            <Col xs={24} sm={24} mdHidden lgHidden>
              <NavBtn>
                <PcListMobileLink onClick={
                  () => {
                    setDrawerBody(DRAWER_BODY.PC_LIST)
                    setOpen(true)
                  }}
                />
              </NavBtn>
            </Col>
            <Col xs={24} sm={24} md={disabledInterface ? 24 : 14} mdPush={disabledInterface ? 0 : 10}>
              {!disabledInterface &&
                <FlexboxGrid>
                  <FlexboxGrid.Item colspan={isTestFail() ? 12 : 24}
                                    style={{ borderLeft: '1px solid rgb(229, 229, 234)' }}>
                    <NavBtn page='test' isActive={openedTestRepairTab === 'test'}
                            onClick={() => dispatch(setOpenedTestRepairTab('test'))}>{t('Test')}</NavBtn>
                  </FlexboxGrid.Item>
                  {isTestFail() &&
                    <FlexboxGrid.Item colspan={12}>
                      <NavBtn page='repair' isActive={openedTestRepairTab === 'repair'}
                              onClick={() => onOpenRepairTab()}>{t('Repair')}</NavBtn>
                    </FlexboxGrid.Item>
                  }
                </FlexboxGrid>
              }
              {disabledInterface && <div style={{ textAlign: 'center', marginTop: '50px' }}>
                <Panel>
                  <h6>{t(areDIResolved ? 'All DTC/Symptoms have been resolved' : 'All Possible Causes have been tested')}</h6>
                  {!areDIResolved &&
                    <div style={{ marginBottom: 15 }}>
                      <p>{t`Those DTC/Symptoms are not resolved:`}</p>
                      <br />
                      <SelectedValueWrapper selectedDiagnosticItems={notResolvedDiagnosticItems} selectDtc={v => dispatch(openDTCModal(v))} />
                    </div>
                  }
                  <p>{t('If you have found the cause for these DTCs, let us know. Your feedback will be used to improve the accuracy of Kubota intelligent Diagnostics.')}</p>
                  <div style={{ marginTop: 10 }}/>
                  <Button onClick={restartDiagnostic}>{t`Restart Diagnostic`}</Button>
                  <Divider/>
                  {
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      {!selectedSerialNumber &&
                        <>
                          <p>
                            <b>{t`Please note: The report cannot be saved without a valid serial number. Enter the serial number to ensure it can be viewed in the Diagnostic History.`}</b>
                          </p>
                        </>
                      }
                      <div style={{ marginTop: 10 }}/>
                      <PdfReport withButton={false} previewBtn />
                    </div>
                  }
                </Panel>
              </div>}
              <div>
                {!disabledInterface && openedTestRepairTab === 'test' && testData.map(td =>
                  <TestTab
                    key={td.testId}
                    debug={debug}
                    testData={td}
                    pcData={getPcNodeById(openedPC)}
                    geTestNotesByPCAndTestId={geTestNotesByPCAndTestId}
                    getTestAnswersByPCAndTestId={getTestAnswersByPCAndTestId}
                    setTestNote={setTestNote}
                    openedPC={openedPC}
                    onAnswer={onAnswer}
                  />)
                }
                {!disabledInterface && openedTestRepairTab === 'repair' &&
                  <RepairTab
                    debug={debug}
                    repairInfo={repairInfo}
                    testData={testData}
                    onConductRepair={onConductRepair}
                  />
                }
              </div>
            </Col>
            {!disabledInterface &&
              <Col xsHidden smHidden md={10} mdPull={14} style={{ height: '100%' }}>
                <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                  <PCList
                    keysToExpand={keysToExpand}
                    onExpand={expandedKeys => setKeysToExpand(expandedKeys)}
                    debug={debug}
                    onOpenPC={onOpenPC}
                    openedPC={openedPC}
                    pcBySelectedSeriesAndDTCs={pcBySelectedSeriesAndDTCs}
                    disabled={disabledInterface}
                  />
                  <div style={{ paddingBottom: 20, textAlign: 'center' }}>
                    <Divider/>
                    <PdfReport withButton={false} previewBtn />
                    <div style={{ marginTop: 10 }}/>
                    <SecondaryButton onClick={exportDiagnosticJSON}>Export project file <FileDownloadIcon/></SecondaryButton>
                    <div style={{ marginTop: 10 }}/>
                    <a href={kitsUrl()} target='_blank' rel='noreferrer'><SecondaryButton>Create an inquiry</SecondaryButton></a>
                  </div>
                </div>
              </Col>
            }
          </Row>
        </Card>
      </Grid>
    </Hotkeys>
  )
}

export default CausesTests
