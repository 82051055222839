export const version = '1.0.51'
export const cacheName = 'dtcsp'
export const localImagesCache = 'localImages'

export const TEST_RESULTS = {
  TESTED: 'tested',
  PASS: 'pass',
  FAIL: 'fail',
  CONDUCT_REPAIR: 'conduct_repair',
  RESOLVED: 'resolved'
}

export const TEST_RESULTS_FULLY = {
  PASS: 'pass',
  FAIL: 'fail',
  RESOLVED: 'resolved'
}

export const SESSION_STATUS = {
  FINISHED: 'finished',
  IN_PROGRESS: 'inProgress'
}

export const DRAWER_BODY = {
  ASK: 'ask',
  PC_LIST: 'pcList',
  PARTIALLY: 'partially',
  TEST_NO_ANSWER: 'testNoAnswer',
  TEST_NO_PROBLEM: 'testNoProblem'
}
