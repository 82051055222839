import styled, { css } from 'styled-components'

const Button = styled.div<{ width?: string; disabled?: boolean }>`
  cursor: pointer;
  background: #009999;
  border-radius: 4px;
  font-size: 18px;
  padding: 10px 20px;
  display: inline-block;
  text-align: center;
  color: white;
  width: ${({ width }) => width || '300px'};

  ${({ disabled }) =>
    disabled &&
    css`
      background: #cccccc; /* Disabled background color */
      color: #666666; /* Disabled text color */
      cursor: not-allowed; /* Disabled cursor */
      pointer-events: none; /* Prevents click events */
    `}
`

export const SecondaryButton = styled(Button)`
  background: white;
  color: #009999;
  border: 1px solid;
`

export const GhostButton = styled(Button)`
  border-radius: 0px;
  background: none;
  border: 1px solid transparent;
`

export const GreenGhostButton = styled(GhostButton)<{ isActive?: boolean, isDisabled?: boolean, width?: string }>`
  border: 1px solid #33CA7F;
  background: ${({ isActive }) => isActive ? '#33CA7F' : 'white' };
  color: ${({ isActive }) => isActive ? 'white' : '#33CA7F'};
  &:hover {
   background-color: #DFF6E7;
   color: #33CA7F};
  }
`
export const RedGhostButton = styled(GhostButton)<{ isActive?: boolean, isDisabled?: boolean, width?: string }>`
  border: 1px solid #FE5E37;
  background: ${({ isActive }) => isActive ? '#FE5E37' : 'white'};
  color: ${({ isActive }) => isActive ? 'white' : '#FE5E37'};
  &:hover {
    color: #FE5E37;
    background-color: #C6593F1A;
  }
`

export const BlueGhostButton = styled(GhostButton)<{ isActive?: boolean, isDisabled?: boolean, width?: string }>`
  border: 1px solid #3790fe;
  background: ${({ isActive }) => isActive ? '#FE5E37' : 'white'};
  color: ${({ isActive }) => isActive ? 'white' : '#3790fe'};
  &:hover {
    background-color: #d9eafe;
    color: #3790fe;
  }
`

export default Button
