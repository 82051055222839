import {Modal, FlexboxGrid } from "rsuite";
import {useTranslation} from "react-i18next";
import Button, {RedGhostButton} from "./button";
import useDiagnostic, { restartDiagnostic } from "../hooks/useDiagnostic";
import {useEffect, useState} from "react";

const RestartModal = ({ open, onClose }) => {
  const [modalOpen, setModalOpen] = useState(open)
  const { initialData }: any = useDiagnostic()
  const { t } = useTranslation()

  useEffect(() => {
    setModalOpen(open)
  }, [open])

  return (
    <Modal backdrop='static' role='alertdialog' open={modalOpen} onClose={() => {
      setModalOpen(false)
      onClose && onClose()
    }} size='md'>
      <Modal.Header>
        <b>{t`Restart Diagnostic`}</b>
      </Modal.Header>
      <Modal.Body>
        <p>{t`A previous session is still in progress. Would you like to restart anyway?`}</p>
      </Modal.Body>
      <Modal.Footer>
        <FlexboxGrid style={{ marginTop: '20px' }} justify='space-between'>
          <FlexboxGrid.Item colspan={10}>
            <Button width='100%' onClick={async () => {
              await restartDiagnostic({ initialData })
              setModalOpen(false)
              onClose && onClose()
            }}>
              {t`Restart`}
            </Button>
          </FlexboxGrid.Item>
          <FlexboxGrid.Item colspan={10}>
            <RedGhostButton width='100%' onClick={() => {
              setModalOpen(false);
              onClose && onClose()
            }}>
              {t`Cancel`}
            </RedGhostButton>
          </FlexboxGrid.Item>
        </FlexboxGrid>
      </Modal.Footer>
    </Modal>
  )
}

export default RestartModal
