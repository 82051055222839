import { Placeholder, Loader, FlexboxGrid, IconButton } from 'rsuite'
import TrashIcon from '@rsuite/icons/Trash'
import Zoom from 'react-medium-image-zoom'
import Slider from 'react-slick'
import React from 'react'
import {useTranslation} from "react-i18next";

const sliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
}

const PhotosRowSlider = ({ images = [], loading, onRemove, entities, imageHandler }) => {
  const { t } = useTranslation()

  if (!images.length) {
    return null
  }

  if (loading) return (
    <Placeholder.Graph active>
      <Loader center />
    </Placeholder.Graph>
  )

  const renderImage = (img, index) =>
      <Zoom>
          <img
            alt={`Image ${index + 1}`}
            src={imageHandler ? imageHandler(img) : img}
            style={{ width: 'unset', height: 'unset', maxHeight: 150, maxWidth: 150 }}
          />
      </Zoom>

  const imageElements = images.map(
    (img, index) =>
      <div style={{ textAlign: 'center' }} key={index}>
        <div>{renderImage(img, index)}</div>
        { onRemove && <IconButton onClick={() => onRemove(entities[index])} style={{ marginTop: 5 }} icon={<TrashIcon fill='red' />}>{t`Remove`}</IconButton>}
      </div>
  )

  if (images?.length && images.length <= 3) {
    return (
      <FlexboxGrid justify='space-around' align='bottom'>
        {imageElements}
      </FlexboxGrid>
    )
  }

  return (
    <Slider {...sliderSettings}>
      {imageElements}
    </Slider>
  )
}

export default PhotosRowSlider
