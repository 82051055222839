import React from 'react'
import { unregister} from '../serviceWorkerRegistration'
import { Panel, FlexboxGrid, Container, Content, Message }  from 'rsuite'
import Button from "./button";
import {restartDiagnostic} from "../hooks/useDiagnostic";

class ErrorBoundary extends React.Component {
  constructor (props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError (error) {
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {}

  render() {
    if (this.state.hasError) {
      return (
        <Container>
          <Content>
             <FlexboxGrid align='middle' justify='center'>
               <FlexboxGrid.Item colspan={12}>
                <Panel header={ <Message showIcon type="error">
                  Error
                </Message>}>
                  <FlexboxGrid style={{ flexDirection: 'column' }} align='middle' justify='space-between'>
                    <FlexboxGrid.Item>
                      <h6>We recommend to restart diagnostic</h6>
                      <br />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item>
                      <Button onClick={async () => {
                        await restartDiagnostic()
                        unregister()
                        window.location.reload()
                      }}>Restart Diagnostic</Button>
                    </FlexboxGrid.Item>
                  </FlexboxGrid>
                </Panel>
               </FlexboxGrid.Item>
             </FlexboxGrid>
          </Content>
        </Container>
      )
    }

    return this.props.children;
  }
}

export default ErrorBoundary
