import React, { useEffect } from 'react'
import { TypeAttributes } from 'rsuite/esm/@types/common'
import { Drawer, FlexboxGrid } from 'rsuite'

import { debounce } from '../utils'
import { SecondaryButton } from './button'
import { useTranslation } from 'react-i18next'

interface Props {
  isOpen: boolean
  onClose: Function
  body: React.ReactNode
  size?: TypeAttributes.Size
  isFull?: boolean,
  closeButton?: boolean
}

const SelectorDrawer = ({ isOpen, onClose, body, size = 'sm', isFull, closeButton = true }: Props) => {
  const [width, setWidth] = React.useState(window.innerWidth)

  const { t } = useTranslation()

  useEffect(() => {
    const debouncedHandleResize = debounce(() => {
      setWidth(window.innerWidth)
    }, 300)

    window.addEventListener('resize', debouncedHandleResize)

    return () => window.removeEventListener('resize', debouncedHandleResize)
  })

  const isBottom = width <= 992
  const full = isFull !== undefined ? isFull : isBottom
  return (
    <Drawer open={isOpen} onClose={onClose} size={size} full={full} placement={isBottom ? 'bottom' : 'right'} closeButton={closeButton}>
      <Drawer.Body style={{ padding: '20px 45px'}}>
        {width <= 992 && full && size !=='xs' &&
        <div style={{ marginBottom: '10px' }}>
          <SecondaryButton width='100%' onClick={onClose}>{t('Close')}</SecondaryButton>
        </div>
        }
        {body}
      </Drawer.Body>
    </Drawer>
  )
}

export default SelectorDrawer
