import React from 'react'
import ReactDOM from 'react-dom'
import 'react-medium-image-zoom/dist/styles.css'
import './index.css'
// import "slick-carousel/slick/slick.css"
// import "slick-carousel/slick/slick-theme.css"
// import './i18'
import App from './App'
// import * as serviceWorkerRegistration from './serviceWorkerRegistration'
// import reportWebVitals from './reportWebVitals'
import { store } from './store/store'
import { Provider } from 'react-redux'
import ErrorBoundary from "./components/errorComponent";

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ErrorBoundary>
        <App />
      </ErrorBoundary>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// console.log('index.tsx')
// serviceWorkerRegistration.register()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
