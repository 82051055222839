import React, { useEffect, useState } from 'react'

const CachedImage = ({ url, src, style = {} }: any) => {
  const [img, setImg] = useState()

  useEffect(() => {
    (async () => {
        const match = await caches.match(url)
        if (match) {
          const blob = await match.blob()
          setImg(URL.createObjectURL(blob))
        } else {
          // setImg(url)
          setImg(src)
        }
      })()
  }, [url])

  return <img alt='img' src={img} style={{ height: '100%', width: '100%', ...style }} />
}

export default CachedImage

