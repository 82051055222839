import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Input, InputGroup, List, Panel, FlexboxGrid } from 'rsuite'
import SearchIcon from '@rsuite/icons/Search'
import { useTranslation } from 'react-i18next'
import {ItemComponent, SelectData} from './selectorlist';
import {equals} from "ramda";

const DiagnosticItemPanel = styled(Panel)`
  &.rs-panel.rs-panel-in .rs-panel-header {
    margin-bottom: 20px;
  }
  .rs-panel-header {
    padding: 0;
    border: none;
    margin-bottom: 0;
    .rs-icon {
      top: 0;
    }
  }
  .rs-panel-body {
    padding-left: 0;
    margin-left: 20px
  }
`

const DiagnosticItemWithChildren = ({ item, onLabelClick, onActionBtnClick, t, expanded }) =>
  (
    <DiagnosticItemPanel header={item.label} collapsible bordered={false} defaultExpanded={expanded}>
      <List>
        {
          item.children.map((child, i) =>
            <List.Item key={child.value} index={i}>
              <ItemComponent
                key={child.value}
                label={child.label}
                value={child.value}
                withSublabel={false}
                onLabelClick={() => onLabelClick(({ ...child, rootId: item.id, root: item }))}
                onActionBtnClick={() => onActionBtnClick({ ...child, rootId: item.id, root: item })}
                actionText={t('Add')}
                t={t}
              />
            </List.Item>
          )}
      </List>
    </DiagnosticItemPanel>
  )

const DiagnosticItemList = ({ data = [], onActionBtnClick, onLabelClick, }) => {
  const [options, setOptions] = useState(data)
  const [searchValue, setSearchValue] = useState('')
  const [expanded, setExpanded] = useState(false)
  const { t } = useTranslation()

  const onSearch = (value: string) => {
    const trimmedValue = value.trim()
    const regex = new RegExp(trimmedValue, 'i')

    if (trimmedValue) {
      setSearchValue(trimmedValue)
      setOptions(data.filter(({ label, children }) => regex.test(label) || (children?.some(child => regex.test(child.label)))
      ))
      setExpanded(true)
    } else {
      setOptions(data)
      setExpanded(false)
    }
  }

  useEffect(() => {
    const regex = new RegExp(searchValue, 'i')
    const filteredData = data.filter(({ label, children }) =>
      regex.test(label) || (children?.some(child => regex.test(child.label)))
    )
    if (!equals(filteredData, options)) {
      setOptions(filteredData)
    }
  }, [data])

  return (
    <>
      <InputGroup inside>
        <InputGroup.Button>
          <SearchIcon/>
        </InputGroup.Button>
        <Input placeholder={t('Search error code, parts, symptoms, etc...')} onChange={onSearch}/>
      </InputGroup>
      <List size='md' style={{ marginTop: 10, overflow: 'auto' }}>
        {options.map((item, index) => (
          <List.Item key={index} index={index}>
            { (item.children && item.children.length)
              ? <DiagnosticItemWithChildren
                  key={`${item.value} ${item.children.length}`}
                  onLabelClick={onLabelClick}
                  onActionBtnClick={onActionBtnClick}
                  item={item}
                  expanded={expanded}
                  t={t}
                />
              : <ItemComponent
                  key={index}
                  id={item.id}
                  label={item.label}
                  value={item.value}
                  withSublabel={item.dtcId}
                  onLabelClick={() => onLabelClick((item))}
                  onActionBtnClick={() => onActionBtnClick(item)}
                  actionText={t('Add')}
                  t={t}
                />
            }
          </List.Item>
        ))}
      </List>
    </>
  )
}

export default DiagnosticItemList
