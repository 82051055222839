import React, { useEffect, useState } from 'react'
import ListItem from './listItem'
import { Tree } from 'rsuite'
import { pick } from 'ramda'
import useDiagnostic from '../hooks/useDiagnostic'
import { useTranslation } from 'react-i18next'
import PlusIcon from '@rsuite/icons/Plus'
import MinusIcon from '@rsuite/icons/Minus'

const DEBUG = false

const pcLabel = ({ t, id, concernedPartResourceIdTranslation, typeOfFailureResourceIdTranslation, marketFrequency = 0 }) => {
  if (marketFrequency > 0) {
    return `<span style="color: red; font-size: 13px">[${t('Real case exists')}]</span><br />${concernedPartResourceIdTranslation} : ${typeOfFailureResourceIdTranslation} ${(DEBUG && id) || ''}`
  }
  return `${concernedPartResourceIdTranslation} : ${typeOfFailureResourceIdTranslation}`
}

const TreeNode = ({ children }) => {
  const [nodeWidth, setNodeWidth] = useState(null);

  useEffect(() => {
    const node = document.querySelector('.rs-tree-node')
    if (node) {
      const width = node.offsetWidth
      setNodeWidth( width)
    }
  }, [window.innerWidth])

  return (
    <div style={{ width: nodeWidth ? `${nodeWidth}px` : 'auto' }}>
      {children}
    </div>
  )
}

export const renderTestResult = (t, testResult) => {
  const result = testResult?.result?.toUpperCase()
  const resultStyles = {
    PASS: { color: 'green' },
    FAIL: { color: 'red' },
    TESTED: { color: 'orange' },
    CONDUCT_REPAIR: { color: 'blue' }
  }
  const resultText = {
    PASS: t('All Good'),
    FAIL: t('Failed'),
    TESTED: t('Tested'),
    CONDUCT_REPAIR: t('Conducted Repair'),
  }

  return result ? <strong style={resultStyles[result]}>{resultText[result]}</strong> : null
}
const pcDebug = pick([
  'id', 'pcId', 'pc', 'priorityWeight', 'calculatedPriority', 'marketFrequencyWeight',
  'calculatedMarketFrequency', 'accumulatedWeight', 'multi'
])
const debugInfo = pcs => pcs //.map(pcDebug)

const PCList = ({ debug, pcBySelectedSeriesAndDTCs, openedPC, onOpenPC, onExpand, keysToExpand = [], disabled = true }) => {
  const { getTestResultByPc, isPCFullyTested } = useDiagnostic()
  const { t } = useTranslation()

  const [expandedKeys, setExpandedKeys] = useState([])

  const handleExpand = expandItemValues => {
    onExpand(expandItemValues)
    setExpandedKeys(expandItemValues)
  }

  const handleSelect = () => {
    setExpandedKeys(expandedKeys)
  }

  useEffect(() => {
      setExpandedKeys((prevState) => [...prevState, ...keysToExpand])
    },
    [keysToExpand.join(' ')]
  )

  return (
    <>
      { debug && <pre>{JSON.stringify(debugInfo(pcBySelectedSeriesAndDTCs), null, 2)}</pre> }
      <Tree
        style={{ height: '100%', maxHeight: '100%', overflow: 'clip' }}
        valueKey='pcId'
        data={pcBySelectedSeriesAndDTCs}
        onExpand={handleExpand}
        defaultExpandItemValues={[openedPC]}
        expandItemValues={expandedKeys}
        onSelect={handleSelect}
        renderTreeIcon={({ children, expand }) => {
          if (children?.length) {
            return expand ? <MinusIcon /> : <PlusIcon />
          }
          return <div style={{ opacity: 0 }} />
        }}
        renderTreeNode={({ marketFrequency, pc, sdi, accumulatedWeight }) => {
          const {
            id,
            possibleCauseResourceIdTranslation,
            concernedPartResourceIdTranslation,
            typeOfFailureResourceIdTranslation
          } = pc
          const testResult = getTestResultByPc(id)
          const isFullyTested = isPCFullyTested(id, sdi.id)

          return (
            <TreeNode key={id}>
              <ListItem
                gray={disabled || isFullyTested}
                onRowClick={item => {
                  if (disabled) return
                  onOpenPC(item)
                }}
                isActive={id === openedPC}
                label={pcLabel({
                  t,
                  id,
                  concernedPartResourceIdTranslation,
                  typeOfFailureResourceIdTranslation,
                  marketFrequency,
                })}
                value={id}
                withArrow={false}
                subLabel={
                  <>
                    <p>{possibleCauseResourceIdTranslation}</p>
                    {renderTestResult(t, testResult)}
                    {/*<p>{pc.id}</p>*/}
                  </>
                }
              />
            </TreeNode>
          )
        }}
      />
    </>
  )
}

export default PCList

