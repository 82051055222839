import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Divider, Grid, Message, Panel, Row, toaster, Loader } from 'rsuite'
import SelectorDrawer from '../components/selectordrawer'
import SelectorList from '../components/selectorlist'
import Selector from '../components/selector'
import styled from 'styled-components'
import Button from '../components/button'
import { toOptions } from '../utils'

import useProductData from '../hooks/useProductData'
import useDiagnostic from '../hooks/useDiagnostic'
import {registerDTCRequest} from "../utils/api";
import userInfo from '../utils/userinfo'
import { useTranslation } from 'react-i18next'

const Title = styled.h6`
  padding-bottom: 5px;
  padding-top: 5px;
`

const RegisterDTC = () => {
  const { getDtcXCUs } = useProductData()
  const [isLoading, setIsLoading] = useState(false)
  const { selectedSeries, selectedModel, selectedRegion, selectedProduct } = useDiagnostic()
  const [open, setOpen] = useState(false)
  const [xCu, setXCU] = useState()
  const [spn, setSPN] = useState()
  const [fmi, setFMI] = useState()
  const [pcode, setPcode] = useState()
  const { t } = useTranslation()

  // const userId = useSelector(state => state.user?.currentUser?._id)
  const user = useSelector(state => state.user?.currentUser)

  const onOpenSelector = (selectorName: string) => {
    setOpen(true)
  }

  const onSelect = (item: any) => {
    setXCU(item)
    setOpen(false)
  }

  const onTextInput = (inputName: string, value: string) => {
    switch (inputName) {
      case 'spn': return setSPN(value)
      case 'fmi': return setFMI(value)
      case 'pcode': return setPcode(value)
    }
  }

  const send = () => {
    const info = userInfo(user)
    setIsLoading(true)
    registerDTCRequest({
      userId: user._id,
      firstName: info.firstName,
      lastName: info.lastName,
      email: info.email,
      company: info.company,
      dealerName: info.dealerName,
      date: new Date(),
      seriesId: selectedSeries.value,
      modelId: selectedModel?.value,
      regionId: selectedRegion.value,
      displayModelSetId: selectedProduct?.value,
      xcu: xCu.value,
      spn: spn,
      fmi: fmi,
      pCodeErrorCode: pcode
    })
      .then(data => {
        setXCU(null)
        setSPN(null)
        setFMI(null)
        setPcode(null)
        toaster.push(<Message showIcon type="success">Request is sent</Message>)
      })
      .catch(error => toaster.push(<Message showIcon type="error"><pre>{error.toString()}</pre></Message>))
      .finally(() => setIsLoading(false))
  }

  return (
    <Grid>
    <SelectorDrawer isOpen={open} onClose={() => setOpen(false)} body={<SelectorList data={toOptions(getDtcXCUs(), 'xcu', 'xcu', t('Details'))} onRowClick={onSelect} />} />
    <Panel header={t('Request to add new DTC')} shaded bordered>
      <Title>{t('DTC Info')}</Title>
      <Selector name='xcu' placeholder='xCU' onOpenSelector={onOpenSelector} value={xCu?.label || ''} field='xCU' />
      <Selector name='spn' placeholder='SPN' text onTextInput={onTextInput} value={spn || ''} field='SPN' />
      <Selector name='fmi' placeholder='FMI' text onTextInput={onTextInput} value={fmi || ''} field='FMI' />
      <Selector name='pcode' placeholder='P-Code/Error Code' text onTextInput={onTextInput} value={pcode || ''} field='P-Code/Error Code' />
      {xCu && spn && fmi &&
      <Row>
        <Divider/>
        {isLoading ? <Loader size='md' /> : <Button onClick={send}>Send Request</Button>}
      </Row>}
    </Panel>
  </Grid>)
}
export default RegisterDTC
