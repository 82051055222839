import React from 'react'
import { FlexboxGrid } from 'rsuite'
import styled from 'styled-components'
import arrowRight from '../images/arrow_right.png'

const ActionDiv = styled.div`
  text-align: right;
  margin-right: 10px;
  color: #009999;
  font-weight: bold;
  font-size: 16px;
`
const LabelDiv = styled.div`
  width: fit-content;
`
const MenuItemRow = styled.div`
  height: 60px;
  background: #F7F8FA;
  border: 1px solid #E0E0E0;
  box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 18px;
  padding-top: 18px;
  &:hover {
    box-shadow: 0px 0px 0px 1px rgb(31 164 165);
  }
`

const MenuItem = ({ label = '', icon = '', onClick, subLabel }: any) =>
  <MenuItemRow onClick={onClick}>
    <FlexboxGrid>
      <FlexboxGrid.Item colspan={3}>
        {icon}
      </FlexboxGrid.Item>
      <FlexboxGrid.Item colspan={18}>
        <LabelDiv>
          <h6>{label}</h6>
        </LabelDiv>
        {subLabel}
      </FlexboxGrid.Item>
      <FlexboxGrid.Item colspan={3}>
        <ActionDiv>
          <img src={arrowRight} width='10' height='10' />
        </ActionDiv>
      </FlexboxGrid.Item>
    </FlexboxGrid>
  </MenuItemRow>

export default MenuItem
