import WechatTemplateIcon from "@rsuite/icons/WechatTemplate";
import {Loader, Input, Modal, SelectPicker} from "rsuite";
import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import Button, { SecondaryButton } from "./button";
import {sendFeedback} from "../utils/api";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";


const pulseAnimation = keyframes`
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.1);
    opacity: 0.7;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`

export const Icon = styled(WechatTemplateIcon)`
  position: absolute;
  right: 15px;
  top: 55px;
  cursor: pointer;
  color: #009999;
  &:hover {
    animation: ${pulseAnimation} 1s infinite;
  }
`

const FeedBackButton = ({ pc, test }) => {
  const { t } = useTranslation()
  const isOnline = useSelector(state => state.application.connectionStatus) === 'online'
  const [openFeedbackModal, setOpenFeedbackModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [feedback, setFeedback] = useState('')
  const [subject, setSubject] = useState('Request')
  const user = useSelector(state => state.user.currentUser)
  const pg = useSelector(state => state.diagnostic.selectedProduct)
  const selectedDiagnosticItems = useSelector(state => state.diagnostic.selectedDiagnosticItems)

  const sessionId = useSelector(state => state.user.sessionInProgress?._id)

  const send = () => {
    if (!feedback || !subject || !pc) return
    setLoading(true)
    sendFeedback({ userId: user._id, feedback, subject, pcId: pc.pcId, productGroupId: pg.value, testId: test.id, diagnosticItem: pc.sdi.label, sessionId, selectedDtcsSymptoms: selectedDiagnosticItems.map(v => v.label).join(', ') }).finally(() => setOpenFeedbackModal(false))
    setFeedback('')
    setLoading(false)
    setOpenFeedbackModal(false)
  }

  useEffect(() => () => {
    setFeedback('')
  }, [])

  return (
    <>
      <Modal open={openFeedbackModal} onClose={() => setOpenFeedbackModal(false)}>
        <Modal.Header>
          <h5>{t`Feedback`}</h5>
        </Modal.Header>
        <Modal.Body>
          {loading && <Loader size='md' />}
          <b>{t`Product`}:</b> {pg?.label}<br />
          <b>{t`Symptom`}(s)/DTC(s):</b> {selectedDiagnosticItems.map(v => v.label).join(', ')}<br />
          <b>{t`Test`}:</b> <span dangerouslySetInnerHTML={{__html: test.testQuestionResourceIdTranslation }} /><br />
          <b>{t`Possible cause`}:</b> {pc?.pc?.concernedPartResourceIdTranslation}<br /><br />
          <SelectPicker disabled={loading} placeholder='Select subject' cleanable={false} onChange={setSubject} value={subject} searchable={false} data={[
            {
              label: 'Improvement request',
              value: 'Improvement request'
            },
            {
              label: 'Wrong Information',
              value: 'Wrong Information'
            },
            {
              label: 'Others',
              value: 'Others'
            },
          ]} />
          <div style={{ padding: 10 }}/>
          <Input as='textarea'
                 disabled={loading}
                 required
                 value={feedback}
                 onChange={setFeedback}
                 maxLength={2000}
                 rows={5}
          />
        </Modal.Body>
        <Modal.Footer style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button disabled={!feedback || !subject || !pc} width='200px' onClick={send}>Send</Button>
          <SecondaryButton width='200px' onClick={() => setOpenFeedbackModal(false)}>Close</SecondaryButton>
        </Modal.Footer>
      </Modal>
      {isOnline && <Icon width={30} height={30} onClick={() => setOpenFeedbackModal(true)} />}
    </>
  )
}

export default FeedBackButton
