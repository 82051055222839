import React from 'react'
import styled from 'styled-components'
import { List, FlexboxGrid } from 'rsuite'
import arrowRight from '../images/arrow_right.png'

const ActionDiv = styled.div`
  text-align: right;
  margin-right: 10px;
  color: #009999;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
`
const LabelDiv = styled.div`
  cursor: pointer;
  width: fit-content;
`

const ListItem = ({ value, label, subLabel, onRowClick, actionText = '', isActive = false, disabled = false, gray = false, withArrow = true }: any) =>
  <List.Item
    onClick={() => !disabled && onRowClick({ label, value })}
    style={{ cursor: disabled ? 'default' : 'pointer', backgroundColor: isActive ? '#00A9AF21' : 'white' }}
  >
    <FlexboxGrid align='middle'>
      <FlexboxGrid.Item colspan={1} />
      <FlexboxGrid.Item colspan={21}>
        <LabelDiv style={{ cursor: disabled ? 'default' : 'pointer' }}>
          <h6 style={{ color: (disabled || gray)  ? 'gray' : 'black' }} dangerouslySetInnerHTML={{__html: label}} />
          <div style={{ color: (disabled || gray) ? 'gray' : 'black' }}>{subLabel}</div>
        </LabelDiv>
      </FlexboxGrid.Item>
      <FlexboxGrid.Item colspan={2}>
        <ActionDiv>{
          actionText
            ? <span>{actionText}</span>
            : withArrow && <img alt={actionText} src={arrowRight} width='10' height='10' />
        }
        </ActionDiv>
      </FlexboxGrid.Item>
    </FlexboxGrid>
  </List.Item>

export default ListItem
