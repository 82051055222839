import styled, { css } from 'styled-components'
import ArrowRight from '../images/arrow_right.png'
import CheckRoundIcon from '@rsuite/icons/CheckRound'
import {FlexboxGrid} from "rsuite";

const LeftArrow = styled.img`
  width: 10px;
  height: 10px;
  float: left;
  margin-right: 10px;
  margin-top: 6px;
  transform: rotate(180deg);
`

const SelectedValue = styled.div`
  display: inline-block;
  background: #F7F8FA;
  border: 1px solid #E0E0E0;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 8px 16px;
  text-align: center;
  margin-left: 10px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  color: ${({ disabled }) => (disabled ? '#A9A9A9' : 'inherit')};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};

  ${({ isActive }) => isActive && css`
    border: 2px solid #009999;
  `}
`

export const NavBtn = styled.div<{ page?: string, isActive?: boolean }>`
  height: 45px;
  line-height: 43px;
  font-size: 16px;
  background: #F7F8FA;
  cursor: pointer;
  color: ${props => props.isActive ? '#009999' : 'black' };
  border-bottom: ${props =>  props.isActive ? '2px solid #009999' : '1px solid #ccc' };
  text-align: center;
  width: 100%;
`

export const NavButton = ({ label, onClick, disabled }) => {
  const buttonStyle = {
    cursor: disabled ? 'default' : 'pointer',
    fontSize: '16px',
    height: '20px',
    display: 'inline-block',
    opacity: disabled ? 0.5 : 1,
    pointerEvents: disabled ? 'none' : 'auto',
  }

  return (
    <div onClick={!disabled ? onClick : undefined} style={buttonStyle}>
      <LeftArrow src={ArrowRight} />
      <b>{label}</b>
    </div>
  )
}

export const SelectedValueWrapper = ({ values = [], selectedDiagnosticItems, selectDtc, disabled = false }) =>
  <FlexboxGrid>{
    selectedDiagnosticItems
      ? selectedDiagnosticItems.map(v =>
        <FlexboxGrid.Item>
          <SelectedValue
            isActive={v.isActive}
            disabled={disabled}
            key={v.label}
            onClick={() => !disabled && selectDtc(v.value)}
          >
            {v.label} {(v.status === 'resolved') && <CheckRoundIcon fill='green '/>}
          </SelectedValue>
        </FlexboxGrid.Item>
      )
      : values.map(v =>
          <FlexboxGrid.Item>
            <SelectedValue disabled={disabled} key={v}>{v}</SelectedValue>
          </FlexboxGrid.Item>
        )
    }
  </FlexboxGrid>

