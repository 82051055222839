import React from 'react'
import { FlexboxGrid } from 'rsuite'
import arrowRight from '../images/arrow_right.png'
import styled from 'styled-components'

const Card = styled.div`
  border: 1px solid #ccc;
  border-radius: 5px;
`
const CardBody = styled.div`
 padding: 5px;
`
const Label = styled.p`
 color: #009999;
 font-size: 14px;
 font-weight: bold;
`
const CardImg = styled.img`
 float: left;
 padding: 5px 0 5px 2px;
`

const CouldntFind = ({ onClick, label, subLabel, img }) =>
  <Card style={{ backgroundColor: '#F7F8FA', padding: '8px 0' }}>
    <CardBody>
      <FlexboxGrid align='middle' onClick={onClick} style={{ cursor: 'pointer' }}>
        <FlexboxGrid.Item colspan={2}>
          <CardImg src={img} />
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={19} style={{ marginLeft: '5px'}}>
          <span>{label}</span>
          <Label>{subLabel}</Label>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={2}>
          <img width={10} height={10} src={arrowRight} style={{ float: 'right', marginRight: '5px' }} />
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </CardBody>
  </Card>

export default CouldntFind
