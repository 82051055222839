import Button, {RedGhostButton, SecondaryButton} from "./button";
import html2PDF from 'jspdf-html2canvas'
import {FaRegFilePdf} from "react-icons/fa";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import useDiagnostic from "../hooks/useDiagnostic";
import {Modal, Row, Col, Container, Divider, Loader, Placeholder} from "rsuite";
import Selector from "./selector";
import { getSummaryJSONExternalState_PDF } from "../hooks/useHistoryData";
import {renderTestResult} from "./pcList";
import {useSelector} from "react-redux";
import {getPhotos} from "../utils/api";
import {localImagesCache, TEST_RESULTS} from "../utils/constants";
import styled from 'styled-components';
import {formatDateLong} from "../utils";
import {RootState} from "../store/store";
import ReactModal from 'react-modal';

const StyledTable = styled.table`
  width: 900px;
  border-collapse: collapse;
  border: 1px solid black;
`

const StyledTh = styled.th`
  border: 1px solid black;
`

const StyledTd = styled.td`
  border: 1px solid black;
  padding: 8px;
`

const StyledDiv = styled.div`
  & b {
    font-weight: bold;
  }

  & span {
    color: ${({ isOk }) => (isOk ? 'green' : 'red')};
  }

  & i {
    font-style: italic;
  }
`

const ReportModal = ({
  openReportModal, summaryJSON, user, selectedSerialNumber, pdfReportClassname, withButton,
  loading, setOpenReportModal, onClose, OnExportToPdf
}) => {
  const { t } = useTranslation()
  return (
    <ReactModal isOpen={openReportModal} style={{
      content: {
        top: '10%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -8%)',
        width: '960px',
        overflow: 'visible'
      },
      overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'start',
        overflow: 'auto',
        paddingTop: '5vh',
        paddingBottom: '5vh',
        zIndex: 10
      }
    }}
    >
      <div style={{ maxHeight: 'none' }}>
        <div id='reportPDF'>
          <ReportBody date={summaryJSON.date}
                      user={user}
                      model={summaryJSON.selectedProduct?.label}
                      sn={selectedSerialNumber}
                      selectedDiagnosticItems={summaryJSON.dtc}
                      tableData={summaryJSON.tableData}
                      pdfReportClassname={pdfReportClassname}
          />
        </div>
        {!withButton &&
          <Modal.Footer>
            <Divider/>
            <RedGhostButton style={{ marginRight: 20 }} disabled={loading} onClick={() => {
              setOpenReportModal(false)
              onClose && onClose(false)
            }}>Close</RedGhostButton>
            <SecondaryButton disabled={loading} onClick={OnExportToPdf}>
              {loading ? <Loader/> : <>{t`Export to PDF`} <FaRegFilePdf/></>}
            </SecondaryButton>
          </Modal.Footer>
        }
      </div>
    </ReactModal>
  )
}

const PdfReport = ({ showPreview, previewBtn = false, withButton = true, externalSession = null, onClose }) => {
  const diagnostic = useSelector((state: RootState) => state.diagnostic)
  const user = useSelector((state: RootState) => state.user?.currentUser)
  const [openModal, setOpenModal] = useState()
  const [loading, setLoading] = useState(false)
  const [openReportModal, setOpenReportModal] = useState(false)
  const { t } = useTranslation()
  const { selectedSerialNumber, setSelector } = useDiagnostic()
  const summaryJSON = getSummaryJSONExternalState_PDF(externalSession || { states: { diagnostic } })

  const onTextInput = (inputName: string, value: string) => {
    setSelector(inputName, value)
  }

  const OnExportToPdf = () => {
    if (!selectedSerialNumber) {
      setOpenModal(true)
    } else {
      downloadPdf()
    }
  }

  const send = () => {
    setOpenModal(false)
    downloadPdf()
  }

  const reportPDF = 'reportPDF'

  const downloadPdf = () => {
    setLoading(true)
    setOpenReportModal(true)
    setTimeout(async () => {
      const pages = document.getElementsByClassName(reportPDF)
      await html2PDF(pages, {
        jsPDF: {
          format: 'a4',
        },
        margin: {
          top: 20,
          right: 20,
          bottom: 20,
          left: 20,
        },
        imageType: 'image/jpeg',
        output: `report.pdf`,
      })
      setLoading(false)
      setOpenReportModal(false)
      onClose && onClose(false)
    }, 3000)
  }

  useEffect(() => {
    setOpenReportModal(showPreview)
  }, [showPreview])

  return (
    <>
      <Modal open={openModal} onClose={() => setOpenModal(false)} size='xs'>
        <Modal.Body>
          <Selector name='serialNumber' placeholder='serial number' text onTextInput={onTextInput} value={selectedSerialNumber || ''} field={t('Serial Number')} />
        </Modal.Body>
        <Modal.Footer style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button disabled={!selectedSerialNumber || (selectedSerialNumber?.length < 5)} width='100px' onClick={send}>Ok</Button>
          <SecondaryButton width='100px' onClick={() => setOpenModal(false)}>Close</SecondaryButton>
        </Modal.Footer>
      </Modal>
      <ReportModal
        openReportModal={openReportModal}
        summaryJSON={summaryJSON}
        user={user}
        selectedSerialNumber={selectedSerialNumber}
        pdfReportClassname={reportPDF}
        withButton={withButton}
        loading={loading}
        setOpenReportModal={setOpenReportModal}
        onClose={onClose}
        OnExportToPdf={OnExportToPdf}
      />
      {previewBtn &&
        <SecondaryButton onClick={() => setOpenReportModal(true)}>
          <span>{t`View diagnostic report`}</span>
        </SecondaryButton>
      }
      {withButton &&
        <SecondaryButton disabled={loading} onClick={OnExportToPdf}>
          {loading ? <Loader /> : <>{t`Export to PDF`} <FaRegFilePdf /></>}
        </SecondaryButton>
      }
    </>
  )
}

const Photo = ({ testPhotos, pcId, pcName }) => {
  const [images, setImages] = useState([])
  const [loadingStatus, setLoadingStatus] = useState(testPhotos.map(() => true))
  const isOnline = useSelector(state => state.application.connectionStatus) === 'online'

  useEffect(() => {
    const fetchImages = async () => {
      let imageBlobsRemote = []

      try {
        if (isOnline && testPhotos?.length) {
          const response = await getPhotos(pcId, testPhotos)
          imageBlobsRemote = response.map(bufferObj => {
            if (bufferObj) {
              const uint8Array = new Uint8Array(bufferObj.data)
              return new Blob([uint8Array], { type: 'image/jpeg' })
            } else {
              return null
            }
          })
        }
        const cache = await caches.open(localImagesCache)
        const imageBlobsLocal = await Promise.all(testPhotos.map(async photo => {
          const cacheKey = `${pcId}/${photo}`
          const cachedResponse = await cache.match(cacheKey)
          console.log('!isOnline cache cache cache', cacheKey, photo)
          if (cachedResponse) {
            return await cachedResponse.blob()
          }
          return null
        }))

        const objectURLs = [...imageBlobsRemote, ...imageBlobsLocal].map((blob, index) => {
          if (blob) {
            setLoadingStatus((prevStatus) => {
              const newStatus = [...prevStatus]
              newStatus[index] = false
              return newStatus
            })
            return URL.createObjectURL(blob)
          } else {
            return null
          }
        })
        setImages(prevImages => [...prevImages, ...objectURLs])
      } catch (e) {
        console.error(e)
      }
    }

    fetchImages()

  }, [JSON.stringify(testPhotos), pcId, isOnline])

  return (
    <div style={{ textAlign: 'center', display: 'inline-block' }}>
      {testPhotos.map((photo, index) => (
        <div
          key={index}
          style={{
            width: 400,
            margin: 'auto',
            display: 'inline-block',
            textAlign: 'center',
          }}
        >
          {loadingStatus[index] ? (
            <Placeholder.Graph active />
          ) : (
            images[index] && (
              <img
                src={images[index]}
                style={{
                  maxWidth: '100%',
                  maxHeight: 500,
                  display: 'block',
                  margin: 'auto',
                  objectFit: 'contain'
                }}
                alt={`Diagnostic for ${pcName}`}
              />
            )
          )}
          <div style={{ paddingTop: 5, textAlign: 'center' }}>{pcName}</div>
        </div>
      ))}
    </div>
  )
}


const ResultTable = ({ tableData, renderTestResult, t, isTestPassedByResolvedDiagnosticItem }) =>
  (
    <StyledTable>
      <thead>
      <tr>
        <StyledTh width="200px">Possible Causes</StyledTh>
        <StyledTh width="120px">Status</StyledTh>
        <StyledTh width="350px">Test Value</StyledTh>
        <StyledTh>Note</StyledTh>
      </tr>
      </thead>
      <tbody>
      {tableData.data.map((rowData, index) => (
        <tr key={index}>
          <StyledTd>{`${rowData.pc.pc.concernedPartResourceIdTranslation} : ${rowData.pc.pc.typeOfFailureResourceIdTranslation}`}</StyledTd>
          <StyledTd>
            {renderTestResult(t, rowData.status, rowData.pc.sdi.id, isTestPassedByResolvedDiagnosticItem) || t('Not tested')}
          </StyledTd>
          <StyledTd>
            {rowData?.testValue?.map((tv, tvIndex) =>
              tv.ssdbInfo.map((ssdb, ssdbIndex) =>
                ssdb.map((s, sIndex) => (
                  <StyledDiv key={`${tvIndex}-${ssdbIndex}-${sIndex}`} isOk={s?.userAnswer?.isOk}>
                    {s.category?.name && <><b>{s.category.name}</b><br/></>}
                    {s?.userAnswer?.value && <span>{s?.userAnswer?.value}</span>}{s?.userAnswer?.type}&nbsp;
                    {s.value_text || s.value && <i> {s.value_text || s.value}</i>}
                  </StyledDiv>
                ))
              )
            )}
          </StyledTd>
          <StyledTd>
            {rowData?.testValue?.map((tv, noteIndex) => (
              <div key={noteIndex}>{tv.notes}</div>
            ))}
          </StyledTd>
        </tr>
      ))}
      </tbody>
    </StyledTable>
  )

export const ReportBody = ({ user, date, reportNumber, dealerName, model, sn, selectedDiagnosticItems, tableData, pdfReportClassname }) => {
  const { t } = useTranslation()
  const { isTestPassedByResolvedDiagnosticItem } = useDiagnostic()

  return (
    <Container style={{ fontSize: 16 }}>
      <div className={pdfReportClassname}>
        <Row>
          <Col xs={12} >
            Date: {formatDateLong(date)}<br />
            Dealer first name: {user.firstName}<br />
            Dealer last name: {user.lastName}<br />
            Dealer email: {user.email}<br />
            Company: {user.company}<br />
          </Col>
          <Col xs={12} style={{ textAlign: 'right' }}>
            <img src='/kubotaLogoOrange.jpg' style={{ height: 100 }}/>
            <h6>Kubota Intelligent Diagnostic</h6>
          </Col>
        </Row>
        <br /><br /><br /><br /><br />
        <Row>
          <Col xs={24} style={{ textAlign: 'center' }}>
            <h1>Diagnostic Report</h1>
          </Col>
        </Row>
        <br /><br />
        <Row>
          <Col>
            <u><h4>Machine information:</h4></u>
            <div style={{ marginLeft: 30 }}>
              <span>
                Model: {model}<br />
              </span>
              <span>
                S/N: {sn}<br />
              </span>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <u><h4>Symptoms/DTCs:</h4></u>
            <div>
              <ul>
                {selectedDiagnosticItems.map(({ label, status, resolvedByPC }) =>
                  <li>
                    {label} {<strong>{status === TEST_RESULTS.RESOLVED ? `${t('Resolved')}: ${resolvedByPC?.pcConcernedPart}` : t`Not Resolved`}</strong>}
                  </li>)
                }
              </ul>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <u><h4>Diagnostic:</h4></u>
            <ResultTable
              tableData={tableData}
              renderTestResult={renderTestResult}
              t={t}
              isTestPassedByResolvedDiagnosticItem={isTestPassedByResolvedDiagnosticItem}
            />
          </Col>
        </Row>
      </div>
      {
        tableData?.data?.some(d => d.testPhotos.length > 0) && (
          <Row className={pdfReportClassname}>
            <Col>
              <div style={{ margin: 10, display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around', alignItems: "flex-end"}}>
                {tableData.data.flatMap(d =>
                  d.testPhotos.map((photo, index) => (
                    <Photo
                      key={`${d.pc.pcId}_${index}`}
                      testPhotos={[photo]}
                      pcId={d.pc.pcId}
                      pcName={`${d.pc.pc.concernedPartResourceIdTranslation} : ${d.pc.pc.typeOfFailureResourceIdTranslation}`}
                    />
                  ))
                )}
              </div>
            </Col>
          </Row>
        )
      }
    </Container>
  )
}

export default PdfReport
