import { createSlice } from '@reduxjs/toolkit'
import { SelectData } from '../components/selectorlist'

const initialState = {
  startTime: null,
  endTime: null,
  selectedSSDBSeries: null,
  selectedSeries: null,
  selectedProduct: null,
  selectedSerialNumber: '',
  selectedHourMeter: '',
  selectedDiagnosticItems: [],
  pcList: [],
  answersToQuestion: [],
  openedPC: null,
  openedTestRepairTab: 'test',
  testReferenceValues: [],
  testAnswers: [],
  testNotes: [],
  testPhotos: [],
  keysToExpand: [],
  memo: '',
  rate: {},
  logs: [],
  initialData: {}
}

export const diagnosticSlice = createSlice({
  name: 'diagnostic',
  initialState,
  reducers: {
    addToLog: (state, { payload }) => {
      state.logs = [...state.logs, payload]
    },
    setLastLoadedOrSavedDiagnostic: (state, { payload }) => {
      state.lastLoadedOrSavedDiagnostic = payload
    },
    clean: () => ({ ...initialState }),
    setStartTime: (state, { payload }) => {
      state.startTime = payload
    },
    setEndTime: (state, { payload }) => {
      state.endTime = payload
    },
    setDiagnosticState: (state, { payload }) => ({ ...payload }),
    setSSDBSeries: (state, { payload }) => {
      state.selectedSSDBSeries = payload
    },
    setSeries: (state, { payload }) => {
      state.selectedSeries = payload
    },
    setProduct: (state, { payload }) => {
      state.selectedProduct = payload
    },
    setSerial: (state, { payload }) => {
      state.selectedSerialNumber = payload
    },
    addDtc: (state, { payload }) => {
      // @ts-ignore
      state.selectedDiagnosticItems = [...state.selectedDiagnosticItems, payload]
    },
    removeDtc: (state, { payload }) => {
      state.selectedDiagnosticItems = state.selectedDiagnosticItems.filter((dtc: SelectData) => dtc.value !== payload.value)
    },
    cleanAllDtc: (state) => {
      state.selectedDiagnosticItems = []
    },
    setPcList: (state, { payload }) => {
      state.pcList = payload
    },
    resolveDiagnosticItem: (state, { payload }) => {
      state.selectedDiagnosticItems = state.selectedDiagnosticItems.map((sdi: any) =>
        sdi.value === (payload.rootId || payload.value)
          ? ({ ...sdi, status: 'resolved', resolvedByPC: { id : payload.pcId, pcConcernedPart: payload.pcConcernedPart } })
          : sdi
      )
    },
    setAnswerToQuestion: (state, { payload: { pcId, questionId, questionLabel, button, buttonLabel } }) => {
      const isReplace = state.answersToQuestion.find((aq: any) => aq.pcId === pcId && questionId === aq.questionId)
      // @ts-ignore
      state.answersToQuestion = isReplace ?
        state.answersToQuestion.map((aq: any) =>
          aq.pcId === pcId && questionId === aq.questionId
            ? ({ pcId, questionId, questionLabel, button, buttonLabel })
            : aq
          )
      // @ts-ignore
      : [...state.answersToQuestion, { pcId, questionId, questionLabel, button, buttonLabel }]
    },
    setOpenedPC: (state, { payload }) => {
      state.openedPC = payload
    },
    setActiveDiagnosticItems: (state, { payload = [] }) => {
      state.selectedDiagnosticItems = state.selectedDiagnosticItems.map(sdi => ({
        ...sdi,
        isActive: !!payload.includes(sdi.id)
      }))
    },
    setOpenedTestRepairTab: (state, { payload }) => {
      state.openedTestRepairTab = payload
    },
    setTestReferenceValue: (state, { payload: { pcId, testId, commonId, optionId, value, isOk, type } }) => {
      const isReplace = state.testReferenceValues.find((rf: any) =>
        rf.pcId === pcId && rf.testId === testId && rf.commonId === commonId && rf.optionId === optionId
      )
      // @ts-ignore
      state.testReferenceValues = isReplace ?
        state.testReferenceValues.map((rf: any) =>
          rf.pcId === pcId && rf.testId === testId && rf.commonId === commonId && rf.optionId === optionId
            ? ({ ...rf, value, isOk, type })
            : rf
        )
        // @ts-ignore
        : [...state.testReferenceValues, { pcId, testId, commonId, optionId, value, isOk, type }]
    },
    setTestAnswers: (state, { payload: { pcId, testId, testLabel, result, resultLabel } }) => {
      const isReplace = state.testAnswers.find((ta: any) => ta.pcId === pcId && testId === ta.testId)
      // @ts-ignore
      state.testAnswers = isReplace ?
        state.testAnswers.map((ta: any) =>
          ta.pcId === pcId && testId === ta.testId
            ? ({ pcId, testId, testLabel, result, resultLabel })
            : ta
        )
        // @ts-ignore
        : [...state.testAnswers, { pcId, testId, testLabel, result, resultLabel }]
    },
    setTestNotes:  (state, { payload: { pcId, testId, notes } }) => {
      const isReplace = state.testNotes.find((ts: any) => ts.pcId === pcId && testId === ts.testId)
      // @ts-ignore
      state.testNotes = isReplace ?
        state.testNotes.map((ts: any) =>
          ts.pcId === pcId && testId === ts.testId
            ? ({ pcId, testId, notes })
            : ts
        )
        // @ts-ignore
        : [...state.testNotes, { pcId, testId, notes }]
    },
    setTestPhotos:  (state, { payload: { pcId, photos } }) => {
      const isReplace = state.testPhotos.find((ts: any) => pcId === ts.pcId)
      // @ts-ignore
      state.testPhotos = isReplace ?
        state.testPhotos.map((ts: any) =>
          pcId === ts.pcId
            ? ({ pcId, photos })
            : ts
        )
        // @ts-ignore
        : [...state.testPhotos, { pcId, photos }]
    },
    setMemo:  (state, { payload }) => {
      state.memo = payload
    },
    setKeysToExpand:  (state, { payload }) => {
      state.keysToExpand = payload
    },
    setInitialData: (state, { payload }) => {
      state.initialData = payload
    },
    reInitiateDiagnostic: (state) => {
      console.log('reInitiateDiagnostic')
      const actionsLogsToClean = [
        'diagnostic/resolveDiagnosticItem',
        'diagnostic/setTestReferenceValue',
        'diagnostic/setTestAnswers'
      ]

      state.logs = state.logs.filter(log => !actionsLogsToClean.includes(log.type))
      state.answersToQuestion = []
      state.openedPC = null
      state.openedTestRepairTab = 'test'
      state.testReferenceValues = []
      state.testAnswers = []
      state.testNotes = []
    },
    setRate: (state, { payload }) => {
      state.rate = { ...state.rate, ...payload }
    },
    cleanAnswers: (state) => {
      state.testAnswers = []
      state.selectedDiagnosticItems = state.selectedDiagnosticItems.map(s => {
        const a = { ...s }
        delete a.status
        delete a.resolvedByPC
        return a
      })
    },
  },
  extraReducers: (builder) => {},
})


export const {
  setStartTime, setKeysToExpand, addToLog, setSSDBSeries, setPcList,
  setSeries, setProduct, setSerial, addDtc, removeDtc, cleanAllDtc, resolveDiagnosticItem,
  clean, setDiagnosticState, setAnswerToQuestion,
  setOpenedPC, setActiveDiagnosticItems, setOpenedTestRepairTab, setTestReferenceValue,
  setTestAnswers, setTestNotes, setTestPhotos, setMemo, setInitialData, reInitiateDiagnostic, cleanAnswers,
  setRate
} = diagnosticSlice.actions

export default diagnosticSlice.reducer
