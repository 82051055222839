import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { fetchWithCredentials } from '../utils/fetcher';
import { RootState, AppThunk } from './store'
import Cookies from 'js-cookie'
import { saveSessionRequest } from '../utils/api'
import {SESSION_STATUS} from "../utils/constants";
// const sleep = (m: number) => new Promise(r => setTimeout(r, m))

export interface UserState {
  currentUser: any,
  token: string,
  status: 'idle' | 'loading' | 'failed',
  sessionInProgress: any,
  sessions: any,
  unsavedDrafts: any
}

const initialState: UserState = {
  currentUser: {},
  token: '',
  status: 'idle',
  sessionInProgress: {},
  sessions: [],
  unsavedDrafts: []
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setCurrentUser: (state, action) => {
      console.log('setcurrent user start')
      state.currentUser = action.payload
      console.log('setcurrent user end')
    },
    setSessionInProgress: (state, action) => {
      state.sessionInProgress = action.payload
    },
    addSession: (state, action) => {
      const sessions = [...state.sessions]
      sessions.push(action.payload)
      state.sessions = sessions
    },
    pushCurrentSessionToDrafts: (state) => {
      const unsavedDrafts = [...state.unsavedDrafts]
      const currentSession = {...state.sessionInProgress}
      currentSession.meta.status = 'finished'
      unsavedDrafts.push(currentSession)
      state.unsavedDrafts = unsavedDrafts
    },
    setSessionHistory: (state, { payload }) => {
      state.status = 'idle'
      state.sessions = payload
      state.unsavedDrafts = []
    },
    finishSession: (state) => {
      state.sessionInProgress.meta.status = SESSION_STATUS.FINISHED
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCurrentUserAsync.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchCurrentUserAsync.fulfilled, (state, action) => {
        state.status = 'idle'
        state.currentUser = action.payload
      })
      .addCase(fetchCurrentUserAsync.rejected, (state) => {
        state.status = 'failed'
      })
      .addCase(fetchToken.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchToken.fulfilled, (state, action) => {
        state.status = 'idle'
        state.token = action.payload
      })
      .addCase(fetchToken.rejected, (state) => {
        state.status = 'failed'
      })
      .addCase(fetchCurrentSessionsAsync.fulfilled, (state, action) => {
        state.status = 'idle'
        state.sessions = action.payload.sessions
        state.unsavedDrafts = []
      })
  },
})

/* export const fetchCurrentUser = (token: string): AppThunk =>
  async dispatch => {
    const decoded = await fetch(`${process.env.REACT_APP_AUTH_URL}/api/decode?token=${token}`)
    const user = await decoded.json()
    dispatch(userSlice.actions.setCurrentUser(user))
  } */

export const fetchCurrentUserAsync = createAsyncThunk(
  'user/fetchCurrentUser',
  async (arg, { getState }: any) => {
    console.log('fetchCurrentUser start')
    console.log(getState())

    try {
      const unsavedDrafts = getState().user?.unsavedDrafts
      console.log('unsavedDrafts', unsavedDrafts)

      if (unsavedDrafts && unsavedDrafts.length > 0) {
        for (const unsavedSession of unsavedDrafts) {
          const savedSession = await saveSessionRequest(unsavedSession)
          console.log('saved unsaved draft', savedSession)
        }
      }

      const draftSession = getState().user?.sessionInProgress
      if (draftSession && draftSession._id.includes('draft')) {
        try {
          // check if draft session is not in unsavedDrafts (via startTime)
          const startTime = draftSession.states.diagnostic.startTime
          if (!unsavedDrafts || !unsavedDrafts.find(_ => _.states.diagnostic.startTime === startTime)) {
            console.log('draft session', getState().user?.sessionInProgress)
            const savedSession = await saveSessionRequest(getState().user?.sessionInProgress)
            console.log('saved draft session', savedSession)
          } else {
            console.log('draft session already saved via unsaved drafts')
          }
        } catch (e) {
          console.log('Error saving draft session')
          console.error(e)
        }
      }
    } catch (e) {
      console.error('Error saving drafts - user probably still offline')
    }


    const decoded: any = await fetchWithCredentials(`${process.env.REACT_APP_API_URI}/me`)
    console.log('fetchCurrentUser result', decoded)
    const user = await decoded.json()

    if (user?.session?.otherData?.loginUrl) {
      console.log('Save login URL', user.session.otherData.loginUrl)
      // localStorage.setItem('loginUrl', user.session.otherData.loginUrl)
      Cookies.set('loginUrl', user.session.otherData.loginUrl, { expires: 365 })
    }
    return user
  }
)

export const fetchCurrentSessionsAsync = createAsyncThunk(
  'user/fetchCurrentSessions',
  async () => {
    const decoded: any = await fetchWithCredentials(`${process.env.REACT_APP_API_URI}/me/sessions`)
    console.log('fetchCurrentSessions result', decoded)
    const result = await decoded.json()
    return result
  }
)

export const fetchToken = createAsyncThunk(
  'user/fetchToken',
  async (token: string) => {
    const data: any = await fetch(`${process.env.REACT_APP_API_URI}/decode/onetime?token=${token}`)
    const result = await data.json()
    return result.token
  }
)

export const { setCurrentUser, setSessionInProgress, setSessionHistory, finishSession, pushCurrentSessionToDrafts } = userSlice.actions

export const selectCurrentUser = (state: RootState) => state.user.currentUser


export default userSlice.reducer
