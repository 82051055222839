import {buildTree, flatten} from "./index"

const R = require('ramda')

// default value if config is not loaded
let weights = {
  commonality: {
    type: 'relative',
    value: 100
  },
  priority: {
    type: 'relative',
    value: 1,
    defaultValue: 5.5
  },
  marketFrequency: {
    type: 'relative',
    value: 10,
    defaultValue: 1
  },
  reportFrequency: {
    type: 'relative',
    value: 10,
    defaultValue: 1
  },
  lowerPriorityByTest: {
    type: 'relative',
    value: 1000,
    defaultValue: 0
  },
  actualCauses: {
    type: 'relative',
    value: 200,
    defaultValue: 0
  }
}

const applyOccuranceWeight = value => value * weights?.['commonality']?.value || 1

export const addOccurance = (items: any[]) => {
  const groupedItems = R.groupBy(R.prop('pcId'), items)
  const result = R.pipe(
    R.mapObjIndexed((group) => ({
      items: group,
      commonality: applyOccuranceWeight(group.length)
    })),
    R.values,
    R.map(
      ({ items, commonality }) => items.map((item: any, _i, group) => ({
        ...item,
        commonality,
        roots: group.map(g => {
          const { sdi, rootId } = g
          return {
            rootId,
            sdi: sdi
          }
        })
      }))
    ),
    R.flatten
  )(groupedItems)

  return result
}

const addWeight = (items: any []) => items.map(item => {
  if (!item?.pcDetail.priority) item.pcDetail.priority = weights.priority?.defaultValue
  if (!item.marketFrequency) item.marketFrequency = weights.marketFrequency?.defaultValue || 0
  if (!item.reportFrequency) item.reportFrequency = weights.reportFrequency?.defaultValue || 0

  const priority = item.pcDetail?.priority * weights.priority?.value
  const marketFrequency = item.marketFrequency * (weights.marketFrequency?.value || 1)
  const reportFrequency = item.reportFrequency * (weights.reportFrequency?.value || 1)

  item.priorityWeight =  weights.priority?.value
  item.calculatedPriority = priority
  item.marketFrequencyWeight = weights.marketFrequency?.defaultValue || 0
  item.calculatedMarketFrequency = marketFrequency
  item.weight = priority + item.commonality + marketFrequency + reportFrequency

  return item
})

const processNode = R.curry((node: any) => {
  const children = R.prop('children', node)
  const childrenWithWeights = children ? R.map(processNode, children) : []
  const maxChildWeight = R.reduce(R.max, 0, R.pluck('accumulatedWeight', childrenWithWeights))
  const accumulatedWeight = Math.max(node.weight, maxChildWeight)
  const sortedChildren = R.sort(R.descend(R.prop('accumulatedWeight')), childrenWithWeights)

  return R.assoc('accumulatedWeight', accumulatedWeight,
    R.assoc('weight', node.weight,
      R.assoc('children', sortedChildren, node)
    ))
})

const applyWeightsAndSort = (data: any) => {
  const processedData = R.map(processNode, data)
  return R.sort(R.descend(R.prop('accumulatedWeight')), processedData)
}


export const sortPcs = (sortWeights: any, pcsTree: any, answersToTests: any, sort_test_result: any) => {
  weights = sortWeights
  const pcs = flatten(pcsTree)
  const pcsWithWeight = R.pipe(
    addOccurance,
    (argument: any) => addWeight(argument, answersToTests, sort_test_result),
  )(pcs)

  return applyWeightsAndSort(buildTree(pcsWithWeight))
}
