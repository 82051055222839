import React, { useEffect, useState } from 'react'
import { Grid, List, Panel, Loader, toaster, Message } from 'rsuite'
import styled from 'styled-components'
import moment from 'moment'

import useHistoryData from '../hooks/useHistoryData'
import {FaRegFilePdf, FaRegTrashAlt} from "react-icons/fa";
import { useSelector } from 'react-redux'
import { RootState } from '../store/store'
import { useTranslation } from 'react-i18next'
import arrowRight from '../images/arrow_right.png'
import PdfReport from "../components/pdfReport";

const LabelDiv = styled.div`
  align-self: center;
  padding-left: 10px;
`
const ActionDiv = styled.div`
  overflow: hidden;
  margin-right: -126px;
  font-weight: bold;
  font-size: 16px;
`
const RightArrow = styled.img`
  width: 10px;
  height: 10px;
  margin-right: 12px;
  position: relative;
  top: -10px;
`
const ActionItem = styled.div`
  display: inline-block;
  height: 90px;
  width: 63px;
  color: #FFF;
  text-align: center;
  padding-top: 14px;
  &:hover {
    box-shadow: 2px 2px 3px 0px rgb(0 0 0 / 70%) inset;
  }
`
const ItemRow = styled.div`
  height: 90px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  overflow: hidden;
  &:hover ${ActionDiv} {
    margin-right: 0;
    transition: margin-right 0.4s;
  }
`

const DiagnosticHistory = () => {
  const { t } = useTranslation()
  const [selectedSession, setSelectedSession] = useState()
  const [openReportModal, setReportModal] = useState(false)
  const { deleteDiagnostic, loading, getDiagnosticHistory } = useHistoryData()
  const fullState = useSelector((state: RootState) => state)
  const history = fullState.user.sessions

  const onDelete = async (e: React.SyntheticEvent<HTMLElement, MouseEvent>, name: string, id: string) => {
    e.stopPropagation()
    try {
      await deleteDiagnostic(name, id)
      getDiagnosticHistory()
    } catch (e) {
      toaster.push(<Message type='error'>Error deleting - please check your network connection</Message>)
    }
  }

  const openReport = async (e: React.SyntheticEvent<HTMLElement, MouseEvent>, session) => {
    e.stopPropagation()
    setSelectedSession(session)
    setReportModal(true)
  }

  useEffect(() => {
    getDiagnosticHistory()
  }, [])

  return (
    <Grid>
      <PdfReport
        showPreview={openReportModal}
        withButton={false}
        externalSession={selectedSession}
        onClose={() => setReportModal(false)}
      />
      <Panel header={t('Diagnostic History')} shaded bordered>
        { loading ? (
          <div><Loader /></div>
        ): (
        <>
          {
            (!history || history.length === 0) ? (
              <p>{t`No sessions found`}</p>
            )
           :
          (
            <List size='md' style={{ marginTop: 10, height: '100%', overflow: 'scroll' }}>
              {history && history.map((item: any, index: number) => {
                const { _id, meta } = item
                const { name, status, date } = meta
                const series = item.states?.diagnostic?.selectedSeries?.label
                const type = item.states?.diagnostic?.selectedProduct?.label
                const dtcs = item.states?.diagnostic?.selectedDiagnosticItems?.slice(0, 3) && item.states?.diagnostic?.selectedDiagnosticItems?.slice(0, 3).map(_ =>_.label).join(', ')
                return (
                  <List.Item key={index} style={{ marginTop: 0,  paddingTop: 0, paddingBottom: 0 }}>
                    <ItemRow>
                      <LabelDiv>
                        <h6 style={{ lineHeight: '35px' }}>{name && name.split('_')[0]} - <b>{t(status)}</b> <span style={{ fontSize: '12px', fontWeight: 'normal', color: '#ddd'}}>{_id}</span></h6>
                        <p>{date && moment(date).format('DD/MM/YYYY @ HH:mm')}<br />
                          {series} - {type} - <b>DTCs:</b> {dtcs}</p>
                      </LabelDiv>
                      <ActionDiv>
                        <RightArrow src={arrowRight} />
                        <ActionItem style={{ backgroundColor: '#009999', paddingTop: 22 }} onClick={(e) => openReport(e, item)}>
                          <FaRegFilePdf size={35}/>
                        </ActionItem>
                        <ActionItem style={{ backgroundColor: '#FE5E37', paddingTop: 22 }} onClick={(e) => onDelete(e, name, _id)}>
                          <FaRegTrashAlt size={35} />
                        </ActionItem>
                      </ActionDiv>
                    </ItemRow>
                  </List.Item>
                )
              })}
            </List>
          )
          }
        </>
        )}
      </Panel>
    </Grid>
  )
}

export default DiagnosticHistory
