import * as R from 'ramda'
import debounce from 'lodash/debounce'
import { useDispatch, useSelector } from 'react-redux'
import { RootState, reducersToSaveToDb, store } from '../store/store'
import { setDiagnosticState } from '../store/diagnostic'
import {setSessionInProgress, setSessionHistory} from '../store/user'
import { push } from 'redux-first-history'
import {
  saveSessionRequest,
  updateSessionRequest,
  deleteIntermediateSessionRequest,
  endSessionRequest,
  getCurrentSessions
} from '../utils/api'
import { getDataByKey } from './useProductData'
import useDiagnostic from './useDiagnostic'
import {SESSION_STATUS} from "../utils/constants";

export interface HistoryItem {
  name: string
  date: number
  status: string
}

const diagnosticPrefix = 'diag_history:'

const getRequiredStates = (entireState, reducersToSaveToDb) =>
  reducersToSaveToDb.reduce((state, reducerName) => {
    state[reducerName] = R.clone(entireState[reducerName])
    return state
  }, {})

const saveToLocalStorage = (state: any, id: any, newId = null) => {
  const key = diagnosticPrefix + (newId ? newId : id)
  console.log('diagnosticIdInProcess key', id, newId, key)
  if (newId) {
    const oldKey = diagnosticPrefix + id
    console.log('diagnosticIdInProcess remove', oldKey)
    localStorage.removeItem(oldKey)
  }

  console.log('diagnosticIdInProcess set', key)
  localStorage.setItem(key, JSON.stringify({ _id: (newId ? newId : id), states: state.states, meta: state.meta }))
}


export const saveSessionStateToDb = async (state, status = SESSION_STATUS.IN_PROGRESS) => {
  const diagnosticIdInProcessId = state.user?.sessionInProgress?._id
  const isFinished = state.user?.sessionInProgress?.meta?.status === SESSION_STATUS.FINISHED

  const states = getRequiredStates(state, reducersToSaveToDb)
  const { selectedProduct } = state.diagnostic;

  let data = {
    meta: {
      name: `${selectedProduct?.label}_${new Date().getTime()}`, // (intermediate)
      date: new Date().getTime(),
      status: isFinished ? SESSION_STATUS.FINISHED : status
    },
    states
  }
  console.log('!diagnosticIdInProcess sessionInProgress', state.user?.sessionInProgress, status, data.meta)
  if (status === SESSION_STATUS.FINISHED) data.states.diagnostic.endTime = new Date().getTime();

  try {

    if (!diagnosticIdInProcessId) {
      console.log('!diagnosticIdInProcess CREATE', !diagnosticIdInProcessId)
      try {
        const savedSession = await saveSessionRequest(data)
        store.dispatch(setSessionInProgress(savedSession))
        console.log('!diagnosticIdInProcess CREATE online')
      } catch (e) {
        data._id = 'draft_' + new Date().getTime()
        console.log('!diagnosticIdInProcess CREATE catch', data, diagnosticIdInProcessId)
        store.dispatch(setSessionInProgress(data))
        saveToLocalStorage(data, data._id)
      }
    } else {
      console.log('diagnosticIdInProcess UPDATE', data, diagnosticIdInProcessId)
      try {
        const savedSession = await updateSessionRequest(data, diagnosticIdInProcessId)
        console.log('diagnosticIdInProcess UPDATE 1st', savedSession, diagnosticIdInProcessId)
        if (diagnosticIdInProcessId?.includes('draft')) {
          console.log('diagnosticIdInProcess UPDATE draft', savedSession, diagnosticIdInProcessId)
          store.dispatch(setSessionInProgress(savedSession))
          saveToLocalStorage(savedSession, diagnosticIdInProcessId, savedSession._id)
        }
        console.log('diagnosticIdInProcess UPDATE end', savedSession, diagnosticIdInProcessId)
      } catch (e) {
        console.info('diagnosticIdInProcess CATCH', e)
        console.log('diagnosticIdInProcess UPDATE CATCH', data, diagnosticIdInProcessId)
        saveToLocalStorage(data, diagnosticIdInProcessId);
      }
    }
  } catch (e) {
    console.error('saveSessionStateToDb', e);
  }
}

export const syncSessions = async (sessions = []) => {
  const sessionsToSaveKeys = Object
    .keys(localStorage)
    .filter(key => key.includes(diagnosticPrefix))

  for (const sessionToSaveKey of sessionsToSaveKeys) {
    try {
      const sessionToSave = JSON.parse(localStorage.getItem(sessionToSaveKey))
      const savedBefore = sessions.find(s => s._id === sessionToSave._id)
      if (!savedBefore || (savedBefore && sessionToSave.meta.date > savedBefore.meta.date)) {
        await updateSessionRequest(sessionToSave, sessionToSave._id)
      }

      localStorage.removeItem(diagnosticPrefix + sessionToSave._id)
    } catch (e) {
      console.error('cant syncSession: ', sessionToSaveKey)
  }
}
}

export const endCurrentSession = async (diagnosticIdInProcess, saveToHistory = false) => {
  console.log('endCurrentSession', diagnosticIdInProcess)
  try {
    if (diagnosticIdInProcess) {
      console.log('endCurrentSession', diagnosticIdInProcess, 'saveToHistory', saveToHistory)
      await endSessionRequest(diagnosticIdInProcess, saveToHistory)
    }
  } catch (e) {
    console.error(e)
  }
}

export const saveCurrentStateToDbDebounced = debounce(saveSessionStateToDb, 2000)


export const getSummaryJSONExternalState_PDF = session => {
  console.log('session', session)
  const { states: { diagnostic }, meta } = session
  const getTestResultByPc = (pcId: number) => diagnostic.testAnswers.find((ta: any) => ta.pcId === pcId && ta.result)
  const getTestAnswersByPCAndTestId = (pcId: number, testId: number) => diagnostic.testAnswers.find((ta: any) => ta.testId === testId && ta.pcId === pcId)
  const getSSDBInfoByTestId = (testId, config) => {
    const testSSDBlink = getDataByKey('_test_ssdb_link')
    const ssdb = getDataByKey('ssdb')
    const ssdbLinks = testSSDBlink.filter(testSSDBlink => testSSDBlink.testId === testId)
    console.log('ssdb ', testId, ssdbLinks)

    const ssdbInfo = ssdb.filter(ssdbCommon =>
      ssdbCommon.productGroups.some(pg => pg.id === diagnostic.selectedProduct.item.ssdbId)
      && R.pluck('ssdbNameId', ssdbLinks).includes(ssdbCommon.service_specification_name_id)
      && ssdbCommon.options.some(opt => R.pluck('ssdbCategoryId', ssdbLinks).includes(opt?.category?.id))
    )

    const configMap = {
      connectorPart: 'connectorParts',
      connectorBoth: 'connectorBothSides',
      connectorHarness: 'connectorHarness'
    }

    return ssdbInfo.map(ssdbCommon => {
      Object.keys(config).forEach(key => {
        if (config[key] === false) {
          delete ssdbCommon[configMap[key]]
        }
      })
      return ({
        ...ssdbCommon,
        options: ssdbCommon.options.filter(opt => R.pluck('ssdbCategoryId', ssdbLinks).includes(opt?.category?.id))
      })
    })
  }
  const getTestForPc = (pcId: number) => {
    const pc_detail = getDataByKey('pc_detail', diagnostic.selectedSeries.label)
    const pcDetails = pc_detail.filter(pcD => pcD.pcId === pcId && diagnostic.selectedProduct.item.mappingGroupId === pcD.mappingGroupId)
    const test = getDataByKey('test', diagnostic.selectedSeries.label)
    const tests = test.filter(t => R.pluck('testId', pcDetails).includes(t.id))
    return tests.map((t: any) => {
      const pcDetailForTest = pcDetails.find(pcD => pcD.testId === t.id)

      return {
        pcId,
        ssdbInfo: getSSDBInfoByTestId(t.id, R.pick(['connectorBoth', 'connectorHarness', 'connectorPart'], pcDetailForTest)),
        ...t
      }
    })
  }
  const getReferenceValueById = (pcId: number, testId: number, commonId: number, optionId: number) => diagnostic.testReferenceValues.find((rf: any) => rf.pcId === pcId && rf.testId === testId && rf.commonId === commonId && rf.optionId === optionId)
  const geTestNotesByPCAndTestId = (pcId: number, testId: number) => diagnostic.testNotes.find((ta: any) => ta.testId === testId && ta.pcId === pcId)
  const geTestPhotos = (pcId: number) => diagnostic.testPhotos.find((tp: any) => tp.pcId === pcId)?.photos || []

  return {
    date: meta ? new Date(meta.date) : new Date(),
    selectedSeries: diagnostic.selectedSeries,
    selectedProduct: diagnostic.selectedProduct,
    dtc: diagnostic.selectedDiagnosticItems,
    tableData: {
      data: diagnostic.pcList.map(pc => ({
        pc,
        testPhotos: geTestPhotos(pc.pcId),
        status: getTestResultByPc(pc.pcId),
        testValue: getTestForPc(pc.pcId)
          .map(t => ({
            id: t.id,
            pcId: t.pcId,
            name: t.testQuestionResourceIdTranslation,
            result: getTestAnswersByPCAndTestId(pc.pcId, t.id)?.result,
            ssdbInfo: t.ssdbInfo.map(ssdb => ssdb.options.map(opt => ({
              ...opt,
              userAnswer: getReferenceValueById(t.pcId, t.id, ssdb.common_id, opt.id)
            }))),
            notes: geTestNotesByPCAndTestId(t.pcId, t.id)?.notes,
          })),
      }))
    },

  }
}

const useHistoryData = () => {
  const dispatch = useDispatch()
  const entireState = useSelector((state: RootState) => state)
  const history = useSelector((state: RootState) => state.historyData.history)
  const { selectedProduct, selectedSeries }: any = useDiagnostic()

  const getDiagnosticHistory = async (): Promise<any> => {
    const historyRegex = new RegExp(diagnosticPrefix)
    const offlineHistory = Object
      .keys(localStorage)
      .filter(key => historyRegex.test(key))
      .map((key: string) => JSON.parse(localStorage.getItem(key)))
      .filter(session => session.meta?.status === SESSION_STATUS.FINISHED)

    let onlineHistory = []

    try {
      onlineHistory = (await getCurrentSessions())?.sessions
    } catch (e) {

    }

    const allSession = onlineHistory.concat(offlineHistory).sort((a, b) => b.meta.date - a.meta.date)

    dispatch(setSessionHistory(allSession))
  }

  const exportDiagnosticJSON = () => {
    const states = getRequiredStates(entireState, reducersToSaveToDb, 'paused')
    const blob = new Blob([JSON.stringify(states)], { type: 'application/json' })
    const url = URL.createObjectURL(blob)
    const a = document.createElement('a')

    a.href = url
    a.download = `${selectedSeries.label}_${selectedProduct.label}_exported-data.json`
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
    URL.revokeObjectURL(url)

    return JSON.stringify(states)
  }

  const loadDiagnostic = (sessionInProgress: any) => {
    console.log('loadDiagnostic', sessionInProgress)
    if (sessionInProgress) {
      store.dispatch(setSessionInProgress(sessionInProgress))
    }
    if (sessionInProgress && sessionInProgress.states && sessionInProgress.states.diagnostic) {
      store.dispatch(setDiagnosticState(sessionInProgress.states.diagnostic))
    }
    if (sessionInProgress && sessionInProgress.states && sessionInProgress.states.router) {
      store.dispatch(push(sessionInProgress.states.router.location.pathname))
    }
  }

  return {
    history,
    getDiagnosticHistory,
    exportDiagnosticJSON,
    deleteIntermediateSessionRequest,
    loadDiagnostic
  }
}

export default useHistoryData
